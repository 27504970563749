import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'src/app/constants/theme';

const useStyles = makeStyles(() => ({
    clickableInputContainer: {
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'space-between',
        padding: '0.75rem 0.5rem',
        border: `1px solid ${colors.textPrimary}`,
        height: 50,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icon: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            fill: colors.grey,
        },
    },
    text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

interface FormClickableInputProps {
    text: string;
    icon: JSX.Element;
    onClick: () => void;
}

export const FormClickableInput = ({ text, icon, onClick }: FormClickableInputProps) => {
    const classes = useStyles();

    return (
        <div className={classes.clickableInputContainer} onClick={onClick}>
            <div className={classes.text}>{text}</div>
            <div className={classes.icon}>{icon}</div>
        </div>
    );
};
