import { AppLocale } from 'src/app/constants/appLocale';
import MoneyFormatter from 'src/app/core/lib/money/MoneyFormatter';

let moneyFormatter: MoneyFormatter;

export function init(locale: AppLocale, defaultCurrency: string) {
    moneyFormatter = new MoneyFormatter(locale, defaultCurrency);
}

interface MoneyFormattingOptions {
    hideZeroDecimals?: boolean;
    zeroDecimalReplace?: string;
    currency?: string;
}

export function formatMoney(value: string, options?: MoneyFormattingOptions): string {
    let formattedValue = moneyFormatter.formatFromString(value, options?.currency || undefined);

    if (!options) return formattedValue;

    const lastThree = formattedValue.substr(formattedValue.length - 3);
    const hasZeroDecimals = lastThree === '.00' || lastThree === ',00';

    if (options.hideZeroDecimals) {
        formattedValue = hasZeroDecimals ? formattedValue.slice(0, -3) : formattedValue;
    }

    if (!options.hideZeroDecimals && options.zeroDecimalReplace) {
        formattedValue = hasZeroDecimals
            ? formattedValue.slice(0, -2) + options.zeroDecimalReplace
            : formattedValue;
    }

    return formattedValue;
}
