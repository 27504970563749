import { Step, StepLabel, Stepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withLocale } from 'src/app/constants/appLocale';
import { colors } from 'src/app/constants/theme';
import { ArrowRight } from 'src/images/icons/ArrowRight';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { StepIcon } from 'src/images/icons/steps/StepIcon';
import { ProgressBarProps } from 'src/view/components/ProgessBar/interfaces';
import $ from './ProgressBar.module.scss';

const useStepperStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        '& .MuiStepLabel-label, .MuiStepLabel-label a': {
            fontWeight: 'bold',
            color: '#1a1b33',
        },
        '& .Mui-disabled': {
            '& .MuiStepLabel-iconContainer': {
                '& svg': {
                    fill: colors.lightGrey,
                    stroke: colors.lightGrey,
                    '& circle': {
                        stroke: colors.lightGrey,
                    },
                },
            },
            '& .MuiStepLabel-labelContainer span': {
                color: colors.lightGrey,
            },
        },
        '& .Mui-completed': {
            '& a': {
                color: `#bcbdc9 !important`,
            },
        },
    },
}));

const useStyles = makeStyles(() => ({
    successCheckIcon: {
        height: 22,
        width: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%;',
        '& svg': {
            transform: 'scale(.875)',
            marginTop: 1,
            stroke: 'none !important',
        },
    },
}));

export const ProgressBar = ({ steps, active }: ProgressBarProps) => {
    const stepperClasses = useStepperStyles();
    const classes = useStyles();

    const stepIsDisabled = (step: number) => step > active;
    const stepIsCompleted = (step: number) => step < active;

    return (
        <Stepper
            activeStep={active}
            classes={stepperClasses}
            className={$.stepperRoot}
            connector={<ArrowRight />}
        >
            {steps.map((step) => (
                <Step
                    key={step.name}
                    disabled={stepIsDisabled(step.step)}
                    completed={stepIsCompleted(step.step)}
                >
                    <StepLabel
                        icon={
                            stepIsCompleted(step.step) ? (
                                <div
                                    className={classNames(
                                        classes.successCheckIcon,
                                        $.successCheckIcon
                                    )}
                                >
                                    <CheckIcon />
                                </div>
                            ) : (
                                <div className={$.stepIcon}>
                                    <StepIcon step={step.step} />
                                </div>
                            )
                        }
                    >
                        {step.to && stepIsCompleted(step.step) ? (
                            <Link to={withLocale(step.to)}>{step.name}</Link>
                        ) : (
                            step.name
                        )}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
