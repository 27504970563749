import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { breakpoints } from 'src/app/constants/breakpoints';
import { colors, layout } from 'src/app/constants/theme';
import useWindowSize from 'src/app/hooks/UseWindowSize';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { Ticket } from 'src/data/models/Ticket';
import { Venue } from 'src/data/models/Venue';
import { InfoMessage } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import { Modal, ModalBody } from 'src/view/components/Modal/Modal';
import { SeatPickerSwipeItem } from 'src/view/components/SeatPicker/SeatPickerSwipeItem';
import { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const useStyles = makeStyles((theme) => ({
    bullets: {
        display: 'flex',
    },
    swiperContainer: {
        marginLeft: '-0.8rem',
        marginRight: '-0.8rem',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2.75),
    },
    bullet: {
        display: 'block',
        height: 3,
        width: 20,
        background: colors.lightGrey,
        borderRadius: layout.borderRadiusBase,
        '&:not(:last-child)': {
            marginRight: 10,
        },
        transition: 'width .5s',
    },
    bulletActive: {
        background: colors.darkBlue,
        width: 30,
        transition: 'width .5s',
    },
    image: {
        width: 'auto',
        maxHeight: 240,
        maxWidth: '100%',
    },
    imageWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(3.75),
    },
    categoryName: {
        marginBottom: 3,
    },
    venueHeader: {
        marginTop: theme.spacing(7.5),
    },
}));

interface SeatSwiperPickerProps {
    tickets: Ticket[];
    venue: Venue;
    selectedTicketCategoryId?: string | null;
    onSelect: (categoryId: string) => void;
}

const SeatSwiperPicker = ({
    tickets,
    venue,
    selectedTicketCategoryId,
    onSelect,
}: SeatSwiperPickerProps) => {
    const classes = useStyles();
    const [swiper, setSwiper] = useState<any>();
    const { windowSize } = useWindowSize();
    const [activeMoreInfoTicket, setActiveMoreInfoTicket] = useState<Ticket | undefined>();
    const { t } = useTranslation();

    useEffect(() => {
        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'seating',
        });
    }, []);

    useEffect(() => {
        if (swiper && selectedTicketCategoryId) {
            const activeIndex = tickets.findIndex((t) => t.categoryId === selectedTicketCategoryId);
            swiper.slideTo(activeIndex);
        }
    }, [swiper, selectedTicketCategoryId]);

    const getSlidesPerView = () => {
        if (tickets.length === 1) return 1;

        return windowSize?.width && windowSize.width <= breakpoints.small ? 1.15 : 1.6;
    };

    return (
        <>
            {tickets.length === 1 && <InfoMessage text={t('ticket_one_category_available')} />}

            <Swiper
                effect="coverflow"
                onSwiper={(s) => setSwiper(s)}
                slidesPerView={getSlidesPerView()}
                centeredSlides
                centeredSlidesBounds
                spaceBetween={20}
                grabCursor={true}
                pagination={{
                    clickable: true,
                    el: '.bullets-pagination',
                    bulletClass: classes.bullet,
                    bulletActiveClass: classes.bulletActive,
                }}
                modules={[Pagination, EffectCoverflow]}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                style={{
                    marginTop: 30,
                }}
                className={classes.swiperContainer}
                onTransitionEnd={(s) => {
                    onSelect(tickets[s.activeIndex].categoryId);
                }}
            >
                {tickets.map((t) => (
                    <SwiperSlide style={{ width: 500 }} key={t.id}>
                        {({ isActive }) => (
                            <SeatPickerSwipeItem
                                ticket={t}
                                venue={venue}
                                isActive={isActive}
                                onClickMoreInfo={(ticket) => setActiveMoreInfoTicket(ticket)}
                            />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={classNames('bullets-pagination', classes.pagination)} />
            <Modal
                open={!!activeMoreInfoTicket}
                onClose={() => setActiveMoreInfoTicket(undefined)}
                title={''}
            >
                <ModalBody>
                    <>
                        <div className={classes.imageWrapper}>
                            <Body
                                small
                                grey
                                align="center"
                                marginTop={false}
                                className={classes.venueHeader}
                            >
                                {venue.name}
                            </Body>

                            <img
                                src={
                                    activeMoreInfoTicket?.seatplanImage ||
                                    '/images/temp/no-seatingplan-image.png'
                                }
                                alt="stadium"
                                className={classes.image}
                            />

                            {!activeMoreInfoTicket?.seatplanImage && (
                                <Body grey marginTop={false} marginBottom={false} align="center">
                                    {t('seatpicker_noimage')}
                                </Body>
                            )}
                        </div>

                        <Heading
                            variant="h4"
                            marginTop={false}
                            marginBottom={false}
                            className={classes.categoryName}
                            bold
                        >
                            {activeMoreInfoTicket?.name}
                        </Heading>
                        <Body marginBottom={false} marginTop={false}>
                            <pre>{activeMoreInfoTicket?.description || t('ticket_seatdetail')}</pre>
                        </Body>
                    </>
                </ModalBody>
            </Modal>
        </>
    );
};

export default SeatSwiperPicker;
