import React, { CSSProperties, useState } from 'react';
import { colors } from 'src/app/constants/theme';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import classnames from 'classnames';
import $ from 'src/app/views/ImageSliderStyles.module.scss';
import { SwiperArrow } from 'src/images/icons/swiperNext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import { CircularProgress } from '@mui/material';

interface ImageSliderProps {
    images: string[];
    imageFit?: 'cover' | 'contain';
    accomodationName?: string;
    enableThumbnails?: boolean;
    maxHeight?: CSSProperties['height'];
    thumbNailsMaxHeight?: CSSProperties['height'];
    containerClassName?: string;
    loading?: boolean;
    initialSlide?: number;
}
export const ImageSlider = ({
    images,
    imageFit = 'contain',
    accomodationName,
    enableThumbnails,
    maxHeight = 300,
    thumbNailsMaxHeight = 100,
    containerClassName,
    loading,
    initialSlide = 0,
}: ImageSliderProps) => {
    const [swiper, setSwiper] = useState<any>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    const isLoopable = () => {
        if (images.length === 1) return false;

        return !loading;
    };

    if (loading)
        return (
            <div className={$.imageLoading}>
                <CircularProgress size={20} />
            </div>
        );

    return (
        <>
            <div className={classnames($.topImages, containerClassName)}>
                <Swiper
                    initialSlide={initialSlide}
                    onSwiper={(s) => {
                        setSwiper(s);
                    }}
                    style={{
                        width: '100%',
                        maxHeight: maxHeight,
                        '--swiper-pagination-color': colors.blue,
                    }}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={isLoopable()}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation, Thumbs, FreeMode]}
                    className="mySwiper"
                    thumbs={{ swiper: thumbsSwiper }}
                >
                    <>
                        {images.map((image, i) => (
                            <SwiperSlide key={`${image}-${i}`}>
                                <img
                                    className={classnames(
                                        $.slideImageStyle,
                                        imageFit === 'contain' && $.slideImageFitContain,
                                        imageFit === 'cover' && $.slideImageFitCover
                                    )}
                                    src={image}
                                    alt={accomodationName || ''}
                                />
                            </SwiperSlide>
                        ))}
                    </>
                    {isLoopable() && (
                        <>
                            <div
                                className={classnames($.navigation, $.navigationPrev)}
                                onClick={() => swiper && swiper.slidePrev()}
                            >
                                <SwiperArrow />
                            </div>
                            <div
                                className={classnames($.navigation, $.navigationNext)}
                                onClick={() => swiper && swiper.slideNext()}
                            >
                                <SwiperArrow />
                            </div>
                        </>
                    )}
                </Swiper>
            </div>
            {enableThumbnails && (
                <div className={$.topImages}>
                    <Swiper
                        initialSlide={initialSlide}
                        style={{
                            width: '100%',
                            maxHeight: thumbNailsMaxHeight,
                        }}
                        onSwiper={(s) => setThumbsSwiper(s)}
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode
                        modules={[Navigation, Thumbs, FreeMode]}
                        className="thumbsSwiper"
                        watchSlidesProgress={true}
                    >
                        <>
                            {images?.map((image, i) => (
                                <SwiperSlide key={`${image}-thumb-${i}`}>
                                    <img
                                        className={classnames(
                                            $.slideImageStyle,
                                            $.slideImageFitCover
                                        )}
                                        src={image}
                                        alt={accomodationName || ''}
                                    />
                                </SwiperSlide>
                            ))}
                        </>
                    </Swiper>
                </div>
            )}
        </>
    );
};
