import { TFunction } from 'i18next';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContactData } from 'src/app/constants/contactData';
import { getFaqItems } from 'src/app/constants/faq';
import { isOlympicsSeries } from 'src/app/constants/olympics';
import { getOnlineStatusByOfficeHours } from 'src/app/helpers/getOnlineStatusByOfficeHours';
import store from 'src/app/store';
import { selectIsB2BMode, selectPartnerConfigCheckout } from 'src/app/store/appSlice';
import ChatService from 'src/data/services/ChatService';
import { UspIcon } from 'src/images/icons/UspIcon';
import { Faq, SupportBlock } from 'src/view/components';
import Card, { CardBody } from 'src/view/components/Card';

export const getFaqData = (t: TFunction<'translation', undefined>) => {
    const isB2BAllowed = selectIsB2BMode(store.getState());
    const isOlympics = isOlympicsSeries(store.getState().event.detailEntity?.series.id);
    const config = selectPartnerConfigCheckout(store.getState());

    const getImageUrl = () => {
        if (isB2BAllowed) {
            return '/images/tony@2x.png';
        }

        return config?.general.support_photo || '/images/support-bart@2x.jpg';
    };

    return {
        help: {
            title: t('support_help_title'),
            text:
                config?.general.support_text ||
                t('support_help_text', {
                    minTime: '09:00',
                    maxTime: '17:00',
                }),
            timezone: config?.general.support_text
                ? ''
                : moment().tz(moment.tz.guess(true)).format('Z'),
            b2bSupportText: t('support_help_text_b2b_1', {
                supportEmail: t('_hardcoded_support_email_b2b'),
                supportPhoneNumber: t('_hardcodedSupportMobileNumberB2B'),
            }),
            b2bSalesText: t('support_help_text_b2b_2', {
                salesEmail: t('_hardcodedSupportSalesEmailB2B'),
                supportPhoneNumber: t('_hardcodedSupportMobileNumberB2B'),
            }),
            b2bTextSupport: t('support_help_text_b2b_1_short'),
            b2bTextSales: t('support_help_text_b2b_2_short'),
            b2bPhoneNumberSales: getContactData(t).b2bPhoneNumberSales,
            b2bPhoneNumberSupport: getContactData(t).b2bPhoneNumberSupport,
            b2bEmailSales: getContactData(t).b2bEmailSales,
            b2bEmailSupport: getContactData(t).b2bEmailSupport,
            email: getContactData(t).email,
            image: {
                url: getImageUrl(),
                title: t('support_help_image_title'),
            },
            icon: {
                url: '/images/icon-whatsapp.svg',
            },
            phoneNumber: getContactData(t).phoneNumber,
            whatsappUrl: getContactData(t).whatsappUrl,
            online: isB2BAllowed
                ? getOnlineStatusByOfficeHours()
                : getOnlineStatusByOfficeHours() || ChatService.getOnlineStatus(),
        },
        faq: {
            title: t('faq_title'),
            faqItems: getFaqItems(t, isOlympics ? 'olympics' : null),
        },
        whyEquipo: {
            title: t('why_equipo_title'),
            values: [
                {
                    title: isOlympics ? t('why_equipo_1_title_olympics') : t('why_equipo_1_title'),
                    popover: t('why_equipo_1_popover'),
                },
                {
                    title: t('why_equipo_2_title'),
                    popover: t('why_equipo_2_popover'),
                },
                { title: t('why_equipo_3_title'), popover: t('why_equipo_3_popover') },
            ],
            icon: {
                component: <UspIcon />,
            },
        },
    };
};

interface FaqBlockFeatureProps {
    hideFaq?: boolean;
}

const FaqBlockFeature = ({ hideFaq }: FaqBlockFeatureProps) => {
    const { t } = useTranslation();
    const faqData = getFaqData(t);
    const isB2BAllowed = useSelector(selectIsB2BMode);

    return (
        <Card>
            {faqData.faq && !hideFaq && (
                <CardBody bottomDivider>
                    <Faq {...faqData.faq} />
                </CardBody>
            )}

            {faqData.help && (
                <CardBody>
                    <SupportBlock {...faqData.help} isB2B={isB2BAllowed} />
                </CardBody>
            )}
        </Card>
    );
};

export default FaqBlockFeature;
