import React, { CSSProperties } from 'react';
import { BaseComponent } from 'src/app/interfaces/BaseComponent';
import classnames, { Argument } from 'classnames';
import $ from './Heading.module.scss';

export type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps extends BaseComponent {
    variant: HeadingTypes;
    marginTop?: boolean;
    marginBottom?: boolean;
    uppercase?: boolean;
    grey?: boolean;
    align?: 'left' | 'center' | 'right';
    bold?: boolean;
    capitalize?: boolean;
    dataCy?: string;
}

export const Heading = ({
    variant = 'h1',
    children,
    className,
    style,
    marginTop = true,
    marginBottom = true,
    uppercase = true,
    grey = false,
    align = 'left',
    bold = false,
    capitalize,
    dataCy = '',
}: HeadingProps) => {
    const extraClasses: Argument = {
        [`${className}`]: !!className,
        [$.noMarginTop]: !marginTop,
        [$.noMarginBottom]: !marginBottom,
        [$.uppercase]: uppercase,
        [$.grey]: grey,
        [$.alignCenter]: align === 'center',
        [$.boldHeading]: bold,
        [$.capitalize]: capitalize,
    };

    const renderHeader = () => {
        switch (variant) {
            case 'h2':
                return (
                    <h2
                        data-cy={dataCy}
                        className={classnames($.heading, $.h2, extraClasses)}
                        style={style}
                    >
                        {children}
                    </h2>
                );
            case 'h3':
                return (
                    <h3
                        data-cy={dataCy}
                        className={classnames($.heading, $.h3, extraClasses)}
                        style={style}
                    >
                        {children}
                    </h3>
                );
            case 'h4':
                return (
                    <h4
                        data-cy={dataCy}
                        className={classnames($.heading, $.h4, extraClasses)}
                        style={style}
                    >
                        {children}
                    </h4>
                );
            default:
                return (
                    <h1
                        data-cy={dataCy}
                        className={classnames($.heading, $.h1, extraClasses)}
                        style={style}
                    >
                        {children}
                    </h1>
                );
        }
    };

    return renderHeader();
};
