import React from 'react';
import classnames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import $ from './PhoneNumberSelect.module.scss';
import FormInput, { FormInputSize } from '../FormInput/FormInput';
import FormSelect from '../FormSelect';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { OptionsListItem } from 'src/view/components/FormSelect/FormSelect';
import { countries } from 'src/app/constants/countries';

interface Props extends WithTranslation {
    options: OptionsListItem[];
    onChangeDialCode?: (value: string) => void;
    onBlurDialCode?: React.EventHandler<React.FocusEvent<HTMLSelectElement>>;
    onBlur?: React.EventHandler<React.FocusEvent<HTMLInputElement>>;
    onChange?: (value: string) => void;
    value: string;
    phoneNumberValidation?: (phoneNumber: string) => string;
    phoneNumber?: string;
    showErrorMessage?: boolean;
    customSelectDisplay?: string;
    errorPhoneNumberMessage?: string;
    errorCountryDialCodeMessage?: string;
    dialCode: string;
    maxLength?: number;
    hasError?: boolean;
    success?: boolean;
}

function PhoneNumberSelect({
    options,
    t,
    phoneNumber,
    onChange,
    onBlur,
    onChangeDialCode,
    onBlurDialCode,
    phoneNumberValidation,
    value,
    showErrorMessage,
    errorCountryDialCodeMessage,
    errorPhoneNumberMessage,
    dialCode,
    maxLength,
    hasError,
    success,
}: Props) {
    const getSelectedCountry = () => countries.find((country) => country.dialCode === dialCode);

    return (
        <div className={$.formContainer}>
            <FormLabel>{t('details_phonenumber')}*</FormLabel>
            <div className={classnames([$.formFields, hasError && $.error, success && $.valid])}>
                {dialCode ? (
                    <div className={$.selectedOption}>
                        <img
                            className={$.selectedImage}
                            src={getSelectedCountry()?.flag}
                            alt={getSelectedCountry()?.name}
                        />
                    </div>
                ) : (
                    <div className={$.selectedOption}>
                        <img
                            className={$.defaultImage}
                            src="/images/temp/blank_flag.png"
                            alt="Default Flag"
                        />
                    </div>
                )}
                <FormSelect
                    options={options}
                    onChange={onChangeDialCode}
                    onBlur={onBlurDialCode}
                    smallHeight
                    value={value || ''}
                    onlyIcon
                    leftPadding={'51px'}
                />
                <FormInput
                    fullWidth
                    onBlur={onBlur}
                    size={FormInputSize.Large}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (phoneNumberValidation) {
                            value = phoneNumberValidation(value);
                        }

                        onChange?.(value);
                    }}
                    value={phoneNumber || ''}
                    required
                    border={false}
                    prefix={dialCode ? getSelectedCountry()?.dialCode : undefined}
                    maxLength={maxLength}
                />
            </div>

            {errorPhoneNumberMessage && ((showErrorMessage && !phoneNumber) || !phoneNumber) && (
                <div className={$.errorMessage}>{errorPhoneNumberMessage}</div>
            )}

            {errorCountryDialCodeMessage &&
                ((!dialCode && showErrorMessage) || (!dialCode && phoneNumber)) && (
                    <div className={$.errorMessage}>{errorCountryDialCodeMessage}</div>
                )}
        </div>
    );
}

export default withTranslation()(PhoneNumberSelect);
