import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './HotelItem.module.scss';
import { Accommodation } from 'src/data/models/Accommodation';
import { Stars } from '..';
import { formatMoney } from 'src/data/services/formatting';
import { Heading } from 'src/view/components/Heading/Heading';
import { Body } from 'src/view/components/Body/Body';
import { CutleryIcon } from 'src/images/icons/CutleryIcon';
import { MoreInfoPriceContainer } from 'src/app/views/MoreInfoPriceContainer';
import { ImageSlider } from 'src/app/views/ImageSlider';
import { fetchAccommodationDetail } from 'src/data/services/accommodations';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {
    accommodation: Accommodation;
    selected: boolean;
    openDetail: (accommodation: Accommodation) => void;
    breakfast: boolean;
    onChange: (accommodation: Accommodation) => void;
    onClickFunction?: (accommodation: Accommodation) => void;
    large?: boolean;
    wide?: boolean;
}

interface HotelItemState {
    images: string[];
    image: string;
    loadingImages: boolean;
}

const noImagesSrc = '/images/no-images-white.png';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HotelItem extends React.Component<Props, HotelItemState> {
    public constructor(props) {
        super(props);
        this.handleDetails = this.handleDetails.bind(this);

        this.state = {
            images: [],
            loadingImages: false,
            image: noImagesSrc,
        };
    }

    public handleDetails(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const { accommodation, openDetail } = this.props;
        event.stopPropagation();
        openDetail(accommodation);
    }

    componentDidMount() {
        const { accommodation } = this.props;

        this.setState({
            loadingImages: true,
        });

        fetchAccommodationDetail(accommodation.id)
            .then((res) => {
                if (res.images && res.images.length > 0) {
                    this.setState({
                        images: res.images.map((img) => img.medium),
                    });
                }

                if (accommodation.image) {
                    this.setState({
                        image: accommodation.image,
                    });

                    return;
                }

                this.setState({
                    image: noImagesSrc,
                });
            })
            .catch(() => {
                this.setState({
                    image: noImagesSrc,
                });

                this.setState({
                    loadingImages: false,
                });
            })
            .finally(() => {
                this.setState({
                    loadingImages: false,
                });
            });
    }

    public render() {
        const { accommodation, breakfast, selected, t, onChange, onClickFunction, large, wide } =
            this.props;
        const onClick = () => {
            onChange(accommodation);
            if (onClickFunction) onClickFunction(accommodation);
        };

        let price = '';
        const containerStyles = classnames([
            $.container,
            wide && $.containerWide,
            selected && $.selected,
            large && $.containerLarge,
        ]);

        const detailsStyles = classnames([
            $.details,
            large && $.detailsLarge,
            wide && $.detailsWide,
        ]);
        const titleWideStyles = classnames([$.title, !wide && $.titleHide]);
        const titleNotWideStyles = classnames([$.title, wide && $.titleHide]);
        const contentStyles = classnames([$.content, large && $.contentLarge]);

        if (accommodation.availability != null) {
            price = breakfast
                ? `${accommodation.availability.cheapestBreakfast?.supplementPP}`
                : `${accommodation.availability.cheapest?.supplementPP}`;
        }

        return (
            <div
                className={containerStyles}
                onClick={onClick}
                onKeyUp={() => onChange(accommodation)}
                role="button"
                tabIndex={0}
            >
                {selected && <div className={$.selectedCheckbox}></div>}
                <div className={classnames(wide && $.imageContainerWide, $.imageContainer)}>
                    <ImageSlider
                        images={
                            this.state.images.length > 0 ? this.state.images : [this.state.image]
                        }
                        imageFit="cover"
                        loading={this.state.loadingImages}
                    />

                    {accommodation.recommended && (
                        <div className={$.recommended}> {t('hotel_recommended')}</div>
                    )}
                </div>
                <div className={$.hotelDetailsWrapper}>
                    <Heading variant="h3" className={titleNotWideStyles}>
                        {accommodation.name}
                    </Heading>
                    {accommodation.stars !== 0 && (
                        <div className={classnames([$.stars, wide && $.starsHide])}>
                            <Stars stars={accommodation.stars} />
                        </div>
                    )}

                    <div className={contentStyles}>
                        <div className={detailsStyles}>
                            <h3 className={titleWideStyles}>{accommodation.name}</h3>
                            {accommodation.stars !== 0 && (
                                <div
                                    className={classnames([
                                        $.stars,
                                        wide && $.starsBlock,
                                        !wide && $.starsHide,
                                    ])}
                                >
                                    <Stars stars={accommodation.stars} />
                                </div>
                            )}
                            <div>
                                {breakfast && accommodation.availability?.breakfastPossible && (
                                    <Body small className={$.breakfast}>
                                        <CutleryIcon /> {t('hotel_includesbreakfast')}
                                    </Body>
                                )}
                                {!breakfast &&
                                    accommodation.availability?.breakfastPossible &&
                                    accommodation.availability.cheapest.breakfastIncluded && (
                                        <Body small marginTop={false}>
                                            <CutleryIcon /> {t('hotel_includesbreakfast')}
                                        </Body>
                                    )}
                                <Body className={$.distance}>
                                    {accommodation.distanceToCityCenter} {t('hotel_tocentre')}
                                </Body>
                                <Body className={$.distance}>
                                    {accommodation.distanceToVenue} {t('hotel_tostadium')}
                                </Body>
                            </div>
                            <MoreInfoPriceContainer
                                text={t('more_info')}
                                marginBottom={0}
                                marginTop="1rem"
                                onClickMoreInfo={this.handleDetails}
                                priceProps={{
                                    price: formatMoney(price, { hideZeroDecimals: true }),
                                    bold: selected,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HotelItem);
