import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectHasConsentedNewsLetter } from 'src/app/store/TravellerDataSlice';
import { selectOrder } from 'src/app/store/orderSlice';
import * as Cache from 'src/data/services/cache';
import { book } from 'src/data/services/order';
import { stopExpiryWatcher } from 'src/data/services/availability';

export function useBookOrder() {
    const order = useSelector(selectOrder);
    const subscribeNewsletter = useSelector(selectHasConsentedNewsLetter);

    const bookOrder = useCallback(async () => {
        if (!order) return;

        const result = await book(order.id, order.secret, {
            subscribeNewsletter,
        });

        Cache.setBooking(result.orderNumber);
        stopExpiryWatcher();
        return result;
    }, [order, subscribeNewsletter]);

    return {
        /** Book the order before creating the payment.
         * Note: the rejection of this Promise is bubbled up
         * because this request always goes right before creating payment request.
         */
        bookOrder,
    };
}
