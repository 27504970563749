import { createTheme } from '@mui/material';
import { colors } from 'src/app/constants/theme';
import { breakpoints } from 'src/app/constants/breakpoints';

export default createTheme({
    palette: {
        success: {
            main: colors.successGreen,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: breakpoints.small,
            md: breakpoints.medium,
            lg: breakpoints.large,
            xl: breakpoints.xLarge,
        },
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: '2rem',
                    marginBottom: '2rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    background: 'white',
                    '.Mui-error': {
                        borderWidth: 1,
                    },
                    '.Mui-focused': {
                        borderWidth: '1px !important',
                    },
                },
                notchedOutline: {
                    borderColor: `${colors.textPrimary} !important`,
                    borderWidth: `1px !important`,
                },
                input: {
                    height: 16,
                },
            },
        },
    },
});
