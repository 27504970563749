import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { colors, layout } from 'src/app/constants/theme';
import { blockFadeStyles } from 'src/app/styles/blockFadeStyles';
import { Ticket } from 'src/data/models/Ticket';
import { Venue } from 'src/data/models/Venue';
import { formatMoney } from 'src/data/services/formatting';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { Price } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import Button from '../Button/Button';

const useStyles = makeStyles((theme) => ({
    swipeItemContainer: {
        width: '100%',
        height: 550,
        padding: theme.spacing(3),
        margin: `${theme.spacing(1)}px 0`,
        border: `1px solid ${colors.lightGrey}`,
        borderRadius: layout.borderRadiusBase,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    swipeItemInfo: {
        flexShrink: 0,
    },
    description: blockFadeStyles(80, 42),
    moreInfoWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    moreInfoButton: {
        background: colors.backgroundPrimary,
        textTransform: 'none',
        width: 'auto',
        padding: '10px 20px',
        height: 'auto',
        color: colors.darkBlue,
        borderRadius: layout.borderRadiusBase,
        '&:hover': {
            background: `${colors.greyHover} !important`,
        },
    },
    divider: {
        margin: `${theme.spacing(3)}px 0`,
    },
    activeSlide: {
        border: `3px solid ${colors.successGreen}`,
        padding: theme.spacing(3) - 2,
    },
    checkedIconContainer: {
        borderTop: `16px solid ${colors.successGreen}`,
        borderLeft: `16px solid ${colors.successGreen}`,
        borderBottom: `16px solid transparent`,
        borderRight: `16px solid transparent`,
        position: 'absolute',
        top: -1,
        left: -1,
        height: 0,
        width: 0,
    },
    checkIcon: {
        position: 'absolute',
        top: -1,
        left: 1,
    },
    image: {
        width: 'auto',
        maxHeight: 240,
        maxWidth: '100%',
        objectFit: 'contain',
    },
    imageWrapper: {
        textAlign: 'center',
        width: '100%',
        height: 300,
    },
}));

interface SeatPickerSwipeItemProps {
    ticket: Ticket;
    venue: Venue;
    isActive?: boolean;
    onClickMoreInfo?: (ticket: Ticket) => void;
}

export const SeatPickerSwipeItem = ({
    ticket,
    venue,
    isActive,
    onClickMoreInfo,
}: SeatPickerSwipeItemProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classNames(classes.swipeItemContainer, isActive && classes.activeSlide)}>
            {isActive && (
                <>
                    <div className={classes.checkedIconContainer}></div>
                    <div className={classes.checkIcon}>
                        <CheckIcon color="white" />
                    </div>
                </>
            )}

            <div className={classes.swipeItemInfo}>
                <Heading variant="h4" marginTop={false}>
                    {ticket.name}
                </Heading>
                <div className={classes.description}>
                    <Body marginTop={false} marginBottom={false}>
                        <pre>{ticket.description || t('ticket_seatdetail')}</pre>
                    </Body>
                </div>
                <div className={classes.moreInfoWrapper}>
                    <Button
                        className={classes.moreInfoButton}
                        text="More info"
                        onClick={() => onClickMoreInfo?.(ticket)}
                        disabled={!onClickMoreInfo}
                    />
                    <Price
                        bold
                        price={formatMoney(ticket.supplementPP.toString(), {
                            hideZeroDecimals: true,
                        })}
                    />
                </div>
            </div>

            <Divider className={classes.divider} />

            <div className={classes.imageWrapper}>
                <img
                    src={ticket.seatplanImage || '/images/temp/no-seatingplan-image.png'}
                    alt="stadium"
                    className={classes.image}
                />

                {!ticket.seatplanImage && (
                    <Body grey marginTop={false} marginBottom={false}>
                        {t('seatpicker_noimage')}
                    </Body>
                )}
            </div>
        </div>
    );
};
