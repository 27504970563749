import * as React from 'react';
import classnames from 'classnames';
import $ from './FormSelect.module.scss';

export interface SelectOption {
    label: string;
    value: string | number;
}

export interface OptionsListItem {
    groupName?: string;
    options: SelectOption[];
}

export interface OptionsListItem {
    groupName?: string;
    options: SelectOption[];
}

interface Props {
    options: OptionsListItem[];
    wide: boolean;
    onChange?: (value: string | number, id?: string) => void;
    onBlur?: React.EventHandler<React.FocusEvent<HTMLSelectElement>>;
    value?: string | number;
    id?: string;
    smallHeight?: boolean;
    onlyIcon?: boolean;
    leftPadding?: string;
    placeholder?: string;
    hasError?: boolean;
    success?: boolean;
}

class FormSelect extends React.Component<Props> {
    public static defaultProps = {
        wide: false,
    };

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    public onChange(option: string | number) {
        const { onChange, id } = this.props;
        if (onChange != null) {
            onChange(option, id);
        }
    }

    public render() {
        const {
            options,
            wide,
            value,
            id,
            smallHeight,
            onlyIcon,
            placeholder,
            hasError,
            success,
            onBlur,
        } = this.props;
        const selectStyles = classnames([
            $.select,
            wide && $.wide,
            smallHeight && $.selectSmall,
            onlyIcon && $.onlyIcon,
            hasError && $.invalid,
            success && $.valid,
        ]);

        const renderOption = (oList: OptionsListItem, o: SelectOption) => (
            <option value={o.value} key={`${oList.groupName}-${o.label}-${o.value}`}>
                {o.label}
            </option>
        );

        return (
            <div className={selectStyles} style={{ paddingLeft: this.props.leftPadding }}>
                <select
                    className={$.selectElement}
                    onBlur={onBlur}
                    onChange={(e) => {
                        if (e.target.value.includes('+')) {
                            this.props.onChange?.(e.target.value, id);

                            return;
                        }

                        const parsedValue = parseInt(e.target.value);

                        if (isNaN(parsedValue)) {
                            this.props.onChange?.(e.target.value, id);

                            return;
                        }

                        this.props.onChange?.(parsedValue, id);
                    }}
                    value={value}
                    id={id}
                >
                    {placeholder && (
                        <option value="" key={`${id}-placeholder`}>
                            {placeholder}
                        </option>
                    )}

                    {options.map((oList) => (
                        <React.Fragment key={`${oList.groupName}-options`}>
                            {oList.groupName ? (
                                <optgroup label={oList.groupName}>
                                    {oList.options.map((o) => renderOption(oList, o))}
                                </optgroup>
                            ) : (
                                oList.options.map((o) => renderOption(oList, o))
                            )}
                        </React.Fragment>
                    ))}
                </select>
            </div>
        );
    }
}

export default FormSelect;
