import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { getNationalityByCode } from 'src/app/constants/nationalities';
import { getDateOfBirthValidation } from 'src/app/forms/genericValidation/dateOfBirthValidation';
import { TravellerBirthDate } from 'src/app/models/TravellerFormModel';
import { getTranslatedCountryByCode } from 'src/app/utils/locale';
import { Restrictions } from 'src/data/models/EventDetail';
import * as Yup from 'yup';

export const getAgeOnEventDate = (t: TravellerBirthDate, eventDate: Moment) => {
    const filledDate = moment(`${t.day}-${t.month}-${t.year}`, 'DD-MM-YYYY');

    return moment(eventDate).diff(filledDate, 'years');
};

type TravelerDetailsFormValidationSchemeArgs = {
    t: TFunction;
    eventDate: Moment;
    restrictions?: Restrictions;
    isB2b?: boolean;
};

const getTravelerDetailsFormValidationScheme = ({
    t,
    eventDate,
    restrictions,
    isB2b,
}: TravelerDetailsFormValidationSchemeArgs) => {
    return Yup.object({
        restrictionsConfirmed: Yup.boolean().test(
            'areRestrictionsConfirmed',
            t('traveldetail_error_required_restrictions'),
            function (t) {
                if (
                    !restrictions?.allowAwayFans ||
                    (restrictions?.blacklistedCountries &&
                        restrictions?.blacklistedCountries.length > 0) ||
                    (restrictions?.blacklistedNationalities &&
                        restrictions?.blacklistedNationalities.length > 0)
                ) {
                    return t === true;
                }

                return true;
            }
        ),
        travellers: Yup.array().of(
            Yup.object().shape({
                extended: Yup.boolean(),
                firstName: Yup.string()
                    .trim()
                    .required(t('traveldetail_error_required_first_name')),
                lastName: Yup.string().trim().required(t('traveldetail_error_required_last_name')),
                nationality: Yup.string()
                    .trim()
                    .required(t('traveldetail_error_required_nationality'))
                    .test(
                        'isNotBlacklistedNationality',
                        ({ value }) =>
                            t('traveldetail_nationality_not_allowed', {
                                nationality: getNationalityByCode(value)?.name,
                            }),
                        (nationality) =>
                            nationality
                                ? !restrictions?.blacklistedNationalities?.includes(nationality)
                                : false
                    ),
                dateOfBirth: getDateOfBirthValidation(t)
                    .test(
                        'isPersonUnder120',
                        t('traveldetail_error_required_age_main_booker_under_120'),
                        (t) => {
                            const castedBirthDay = t as TravellerBirthDate;

                            return getAgeOnEventDate(castedBirthDay, eventDate) <= 120;
                        }
                    )
                    .when('extended', {
                        is: true,
                        then: getDateOfBirthValidation(t).test(
                            'isPersonAbove18',
                            t('traveldetail_error_required_age_main_booker'),
                            (t) => {
                                const castedBirthDay = t as TravellerBirthDate;

                                return getAgeOnEventDate(castedBirthDay, eventDate) >= 18;
                            }
                        ),
                        otherwise: getDateOfBirthValidation(t),
                    }),
                addressLine1: Yup.string().when('extended', {
                    is: (value: boolean) => value && restrictions?.requireFullCustomerAddress,
                    then: Yup.string()
                        .trim()
                        .required(t('traveldetail_error_required_address_line_1')),
                }),
                city: Yup.string().when('extended', {
                    is: (value: boolean) => value && restrictions?.requireFullCustomerAddress,
                    then: Yup.string().trim().required(t('traveldetail_error_required_city')),
                }),
                country: Yup.string().when('extended', {
                    is: true,
                    then: Yup.string()
                        .trim()
                        .required(t('traveldetail_error_required_country'))
                        .test(
                            'isNotBlacklistedCountry',
                            ({ value }) =>
                                t('traveldetail_country_not_allowed', {
                                    country: getTranslatedCountryByCode(value),
                                }),
                            function (country) {
                                return country
                                    ? !restrictions?.blacklistedCountries?.includes(country)
                                    : false;
                            }
                        ),
                    otherwise: Yup.string(),
                }),
                email: Yup.string().when('extended', {
                    is: true,
                    then: Yup.string().trim().required(t('traveldetail_error_required_email')),
                    otherwise: Yup.string().email(t('traveldetail_error_email_invalid')),
                }),
                phoneNumber: Yup.object().when('extended', {
                    is: true,
                    then: Yup.object().shape({
                        dialCode: Yup.string()
                            .trim()
                            .required(t('traveldetail_error_required_phone_country_dial_code')),
                        phoneNumber: Yup.string()
                            .trim()
                            .required(t('traveldetail_error_required_phone_number')),
                    }),
                    otherwise: Yup.object(),
                }),
                companyName: Yup.string().test(
                    'companyIsRequired',
                    t('traveldetail_error_required_company_name'),
                    function (value) {
                        if (isB2b && !value) return false;

                        return true;
                    }
                ),
            })
        ),
    });
};

export default getTravelerDetailsFormValidationScheme;
