import AdyenCheckout from '@adyen/adyen-web';
import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'src/app/hooks/use-navigate';
import { PaymentAuthorised } from 'src/app/pages/Completed/PaymentAuthorised';
import { useTypedDispatch } from 'src/app/store';
import { resetSession, selectReferrerUrl, selectSession } from 'src/app/store/appSlice';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { KNOWN_ADYEN_IFRAME_ERROR_MESSAGE, getAdyenConfiguration } from 'src/app/utils/adyen';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { getLastKnownEntryPoint } from 'src/app/utils/lastKnownEntryPointHelper';
import { ErrorMessage, InfoMessage, Page } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import { Heading, HeadingTypes } from 'src/view/components/Heading/Heading';
import { VSpacer } from 'src/view/components/Page';
import { Loading } from '../Loading/Loading';

export interface PaymentResult {
    resultCode: ResultCode;
    sessionData: string;
    sessionResult: string;
    refusalReason?: string;
}

export const Completed = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useTypedDispatch();

    const { orderId, orderSecret, redirectResult, resultCodeFromUrl, sessionId } = useMemo(() => {
        const parameters = new URLSearchParams(window.location.search);

        return {
            sessionId: parameters.get('sessionId'),
            orderId: parameters.get('orderId') || parameters.get('order_id'),
            orderSecret: parameters.get('orderSecret'),
            // Note: redirectResult is only available if redirect takes place (e.g. IDEAL)
            // resultCode appears only for credit card, which doesn't involve a redirect.
            redirectResult: parameters.get('redirectResult'),
            resultCodeFromUrl: parameters.get('resultCode'),
        };
    }, []);

    const [resultCode, setResultCode] = useState<string | null>(resultCodeFromUrl);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!resultCode) return;

        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'completed',
            step_data: resultCode,
        });
    }, [resultCode]);

    useEffect(() => {
        const getPaymentResult = async () => {
            const shouldSubmitResult = redirectResult && sessionId;
            if (shouldSubmitResult) {
                try {
                    const checkout = await AdyenCheckout(
                        getAdyenConfiguration({
                            sessionId,
                            locale: i18n.language,
                            onPaymentCompleted: (result) => {
                                setResultCode(result.resultCode);
                            },
                        })
                    );
                    checkout.submitDetails({ details: { redirectResult } });
                } catch (error) {
                    if ((error as Error)?.message !== KNOWN_ADYEN_IFRAME_ERROR_MESSAGE) {
                        Sentry.captureException(error, (scope) => {
                            scope.setTag('origin', 'Completed.tsx');

                            return scope;
                        });
                    }
                    setError(String(error) || 'Checkout not initialized');
                }
            }
        };

        getPaymentResult();
    }, [sessionId, redirectResult]);

    const partnerReferrerUrl = useSelector(selectReferrerUrl);
    const homeUrl = partnerReferrerUrl ? partnerReferrerUrl?.toString() : '';

    const goToHome = useCallback(() => {
        if (homeUrl) window.location.href = homeUrl;
    }, [homeUrl]);

    useEffect(() => {
        // If there is no orderId, we redirect to the home page. Means that something went terribly wrong.
        if (!orderId) goToHome();
    }, []);

    const isPaymentMadeViaIFrame = sessionId && orderSecret;

    // These are values we get ONLY if the payment is made via legacy payment method (fallback)
    const { orderNumber, status } = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);

        return { orderNumber: urlParams.get('order_number'), status: urlParams.get('status') };
    }, [window.location.search]);

    // If the payment is made via legacy payment method (fallback), we get status from the URL
    const isRedirectedPaymentSuccess = status === 'success';

    const isPaymentSuccessful = useMemo(() => {
        return (
            (isAdyenResultCode(resultCode) &&
                ADYEN_ACCEPTED_PAYMENT_RESULT_CODES.includes(resultCode)) ||
            isRedirectedPaymentSuccess
        );
    }, [isRedirectedPaymentSuccess, resultCode]);

    useEffect(() => {
        /** Reset session after success, so that user cannot go back and stay in the /payment page */
        if (isPaymentSuccessful) {
            dispatch(resetSession);
        }
    }, [isPaymentSuccessful]);

    if (!orderId) return null;

    return (
        <Page step={4} hideCountDown>
            {error && <ErrorMessage content={error} />}

            {/* <---- iFrame payment method ---> */}
            {isPaymentMadeViaIFrame && resultCode && (
                <>
                    <PaymentResult
                        resultCode={resultCode}
                        orderId={orderId}
                        orderSecret={orderSecret}
                    />
                    <VSpacer />
                    {isPaymentSuccessful && (
                        <Button
                            icon="/images/icon-home.svg"
                            text={t('thanks_returnhome')}
                            onClick={goToHome}
                            dataCy="go-to-homepage"
                        />
                    )}
                </>
            )}
            {/* <---- End of iFrame payment method ---> */}

            {/* <---- Start of fallback payment method -----> */}
            {!isPaymentMadeViaIFrame && isRedirectedPaymentSuccess && (
                <>
                    <Heading variant={'h1'}>
                        {t('thankYouForYourOrder', 'Thank you for our order!')}
                    </Heading>
                    <p>
                        {t('payment_request_order_number', 'Order number')}:{' '}
                        <strong>{orderNumber}</strong>
                    </p>
                    <Button
                        icon="/images/icon-home.svg"
                        text={t('thanks_returnhome')}
                        onClick={goToHome}
                        dataCy="go-to-homepage"
                    />
                </>
            )}
            {!orderSecret && !isRedirectedPaymentSuccess && (
                <>
                    <ErrorMessage content={t('general_error')} />
                    <NeedHelpInfoMessage t={t} />
                    <VSpacer />
                    <RetryPaymentButton />
                </>
            )}
            {/* <---- End of fallback payment method -----> */}
            {!resultCode && !error && sessionId && <Loading />}
        </Page>
    );
};

const RetryPaymentButton = () => {
    const session = useSelector(selectSession);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const navigateToPaymentPage = () => {
        const paymentPageLink = RouteHelper.getPaymentRoute(session?.eventId || '');
        navigate(paymentPageLink);
    };

    const navigateToRetry = () => {
        if (!session) {
            window.location.href = getLastKnownEntryPoint() || '';

            return;
        }
        navigateToPaymentPage();
    };

    return (
        <Button
            data-track-payment-retry-button="1"
            text={t('retryPayment', 'Retry Payment')}
            onClick={navigateToRetry}
        />
    );
};

function NeedHelpInfoMessage({ t }: { t: TFunction }) {
    return (
        <InfoMessage
            text={t(
                'needHelpFinalizingPayment',
                'Need help? Or having trouble finalizing your payment? Call us on +443308080559.'
            )}
        />
    );
}

function PaymentResult({
    resultCode,
    orderId,
    orderSecret,
}: {
    resultCode: string | undefined;
    orderId: string;
    orderSecret: string;
}) {
    const { t } = useTranslation();

    const headingVariant: HeadingTypes = 'h1';

    if (resultCode === ADYEN_RESULT_CODES.AUTHORISED || resultCode === ADYEN_RESULT_CODES.PENDING) {
        return (
            <PaymentAuthorised
                orderId={orderId}
                orderSecret={orderSecret}
                resultCode={resultCode}
            />
        );
    }

    return (
        <>
            <Heading variant={headingVariant} dataCy="payment-completed-error">
                {t('payment_completed_error_title')}
            </Heading>
            <ErrorMessage
                content={
                    resultCode === 'Cancelled'
                        ? t('payment_completed_error_cancelled_description')
                        : t('payment_completed_error_general_description')
                }
            />
            <NeedHelpInfoMessage t={t} />
            <VSpacer />
            <RetryPaymentButton />
            <VSpacer />
        </>
    );
}

function BackToTicketPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const session = useSelector(selectSession);

    const ticketPagePath = session
        ? RouteHelper.getTicketRoute(session.eventId, {
              category_id: session.baseTicketCategoryId || undefined,
          })
        : undefined;

    const navigateToTicketPage = () => {
        if (ticketPagePath) navigate(ticketPagePath);
    };

    return (
        <Button
            onClick={navigateToTicketPage}
            text={t('payment_completed_error_retry_order')}
            dataCy="payment-error-retry"
        />
    );
}

/** https://docs.adyen.com/online-payments/build-your-integration/payment-result-codes/ */
const ADYEN_RESULT_CODES = {
    AUTHORISED: 'Authorised',
    CANCELLED: 'Cancelled',
    ERROR: 'Error',
    PARTIALLY_AUTHORISED: 'PartiallyAuthorised',
    PENDING: 'Pending',
    RECEIVED: 'Received',
    REFUSED: 'Refused',
} as const;

type ResultCode = (typeof ADYEN_RESULT_CODES)[keyof typeof ADYEN_RESULT_CODES];

function isAdyenResultCode(code: any): code is ResultCode {
    return Object.values(ADYEN_RESULT_CODES).includes(code);
}

/** Refer to docs why 'PartiallyAuthorised and 'Received' are not considered as success:
 * https://docs.adyen.com/online-payments/build-your-integration/payment-result-codes/
 */
const ADYEN_ACCEPTED_PAYMENT_RESULT_CODES: ResultCode[] = [
    ADYEN_RESULT_CODES.AUTHORISED,
    ADYEN_RESULT_CODES.PENDING,
];
