import { Unsubscribe } from 'redux';
import moment from 'moment-timezone';
import { expireAvailability, selectAvailability } from 'src/app/store/orderSlice';
import store from 'src/app/store';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { selectInitializedSession, selectSession } from 'src/app/store/appSlice';
import { Availability } from 'src/data/models/Availability';

const { dispatch } = store;

let unsubscribeExpiryWatcher: Unsubscribe | null = null;
let availabilityResetTimeout: number | null = null;
let availability: Availability | null;

export const stopExpiryWatcher = () => {
    if (!unsubscribeExpiryWatcher) {
        return;
    }

    if (availabilityResetTimeout !== null) {
        clearTimeout(availabilityResetTimeout);
    }

    unsubscribeExpiryWatcher();

    unsubscribeExpiryWatcher = null;
};

export const startExpiryWatcher = () => {
    if (unsubscribeExpiryWatcher !== null) {
        stopExpiryWatcher();
    }

    unsubscribeExpiryWatcher = store.subscribe(() => {
        const initializedSession = selectInitializedSession(store.getState());

        if (!initializedSession) {
            return;
        }

        const oldAvailability = availability;
        availability = selectAvailability(store.getState());
        const session = selectSession(store.getState());

        if (!availability || !session?.eventId || availability.hash === oldAvailability?.hash) {
            return;
        }

        const resetAndRedirect = () => {
            dispatch(expireAvailability());
            window.location.href = RouteHelper.getSessionEndRoute(session.eventId);
        };

        const secondsTillExpiry = moment(availability.expiryTime).diff(moment(), 'seconds');

        if (secondsTillExpiry <= 0) {
            resetAndRedirect();
        }

        if (availabilityResetTimeout !== null) {
            clearTimeout(availabilityResetTimeout);
        }

        availabilityResetTimeout = window.setTimeout(() => {
            resetAndRedirect();
        }, secondsTillExpiry * 1000);
    });
};
