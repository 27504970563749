export const colors = {
    successGreen: '#00af87',
    successGreenLight: '#00af8714',
    warningYellow: '#f9ae00',
    warningYellowLight: '#f9ae0014',
    darkBlue: '#25273e',
    grey: '#8b98b1',
    lightGrey: '#8b98b1',
    lightestGrey: '#d5d5d5',
    lightFlamingo: '#ffeaea',
    red: '#d93030',
    lightStone: '#c8d3de',
    blue: '#53b8e2',
    linkColor: '#53b8e2',
    backgroundPrimary: '#f1f2f5',
    greyHover: '#e5e5e8',
    textPrimary: '#212141',
    yellow: '#FFC300',
    black: '#1a1b33',
};

export const layout = {
    borderRadiusBase: 2,
};

export const fontSizes = {
    sm1: '0.875rem',
};
