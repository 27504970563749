import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isOlympicsSeries } from 'src/app/constants/olympics';
import { useTypedDispatch } from 'src/app/store';
import { selectReferrerUrl, selectSession } from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { resetOrder, selectOrder } from 'src/app/store/orderSlice';
import { EventRouteParams, RouteHelper } from 'src/app/utils/RouteHelper';
import { Page, TakeOverMessage } from 'src/view/components';
import $ from './SessionEnd.module.scss';
import { getLastKnownEntryPoint } from 'src/app/utils/lastKnownEntryPointHelper';
import { appConstants } from 'src/app/constants/app';

export const SessionEnd: React.FunctionComponent<RouteComponentProps> = () => {
    const { t } = useTranslation();
    const eventDetail = useSelector(selectEventDetail);
    const session = useSelector(selectSession);
    const order = useSelector(selectOrder);
    const dispatch = useTypedDispatch();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);

    const goBack = () => {
        let link: string|null = null;

        if (eventDetail) {
            const params = {} as EventRouteParams;

            if (order) {
                params.package_type = order.packageType.toLocaleLowerCase();
            }

            if (session) {
                params.category_id = session.baseTicketCategoryId || undefined;
            }

            link = RouteHelper.getTicketRoute(eventDetail.id, params);
        }

        dispatch(resetOrder());

        if (!link || link.length === 0) {
            link = getLastKnownEntryPoint();
        }

        if (!link || link.length === 0) {
            link = partnerReferrerUrl?.toString() || null;
        }

        if (!link || link.length === 0) {
            link = appConstants.fallbackWebsite;
        }

        // Do not use react router or navigate here because these are full urls.
        window.location.href = link;
    };

    const isOlympicSeries = isOlympicsSeries(eventDetail?.serie.id || '');

    const getImage = () => {
        if (isOlympicSeries) {
            return '/images/session-end-olympics.jpg';
        }

        return '/images/sessionend.png';
    };

    return (
        <Page step={1} mustHideTravelDetails>
            <div className={$.wrap}>
                <TakeOverMessage
                    callToActionLabel={t('sessionend_bookthisevent')}
                    description={t('sessionend_hasendeddescription')}
                    eventName={eventDetail && eventDetail.name}
                    imageAlt={t('sessionend_sessionhasended')}
                    imageSrc={getImage()}
                    onCallToActionClick={goBack}
                    title={t('sessionend_bookingalmostcomplete')}
                    copyRight={isOlympicSeries ? '@Pexels' : undefined}
                />
            </div>
        </Page>
    );
};
