import { ReactNode } from 'react';
import $ from './Card.module.scss';

interface Props {
    children: ReactNode;
    variant?: 'normal' | 'inverted';
    className?: string;
}

export default ({ children, className = '', variant = 'normal' }: Props) => {
    const variantClass = variant === 'inverted' ? $.variantInverted : '';

    return <div className={`${$.root} ${className} ${variantClass}`}>{children}</div>;
};
