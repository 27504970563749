import React from 'react';

export const CutleryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.919 19">
        <g data-name="cutlery (2)">
            <path
                data-name="Path 8181"
                d="M71.792 0c-1.9 0-3.34 2.643-3.34 4.454A3.347 3.347 0 0 0 70.679 7.6v10.287a1.113 1.113 0 0 0 2.227 0V7.6a3.347 3.347 0 0 0 2.227-3.146C75.133 2.642 73.7 0 71.792 0z"
                style={{ fill: '#53b8e2' }}
                transform="translate(-68.452)"
            />
            <path
                data-name="Path 8182"
                d="M298.463 0a.557.557 0 0 0-.557.557v3.9h-1.113v-3.9a.557.557 0 0 0-1.113 0v3.9h-1.113v-3.9a.557.557 0 0 0-1.114 0v5.567a1.672 1.672 0 0 0 1.67 1.67v10.093a1.113 1.113 0 0 0 2.227 0V7.795a1.672 1.672 0 0 0 1.67-1.67V.557a.557.557 0 0 0-.557-.557z"
                transform="translate(-285.101)"
                style={{ fill: '#53b8e2' }}
            />
        </g>
    </svg>
);
