import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    detailsHighlightedNationalities,
    getHighlightedNationalities,
    nationalities,
} from 'src/app/constants/nationalities';
import { countries, getHighlightedCountries } from 'src/app/constants/countries';
import { FormInput, FormSelect, FormDateSelect } from 'src/view/components';
import { FormInputSize } from 'src/view/components/FormInput/FormInput';
import * as Cache from 'src/data/services/cache';
import { checkIfValidDate, seperateDate } from 'src/app/utils/utils';
import PhoneNumberSelect from 'src/view/components/PhoneNumberSelect';
import FormHintMessage from 'src/view/components/FormHintMessage/FormHintMessage';
import { Heading } from 'src/view/components/Heading/Heading';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { postVerifyEmail, VerifyEmailResponse } from 'src/data/services/email';
import FormErrorMessage from 'src/view/components/FormErrorMessage/FormErrorMessage';
import $ from './DetailsTraveler.module.scss';
import { OptionsListItem, SelectOption } from 'src/view/components/FormSelect/FormSelect';

interface Props {
    bookOther?: boolean;
    booker: boolean;
    callback: (travelerDetails: { [id: string]: string | undefined }) => void;
    cache: {
        firstName?: string;
        lastName?: string;
        birthday?: string;
        email?: string;
        phoneNumber?: string;
        nationality?: string;
        countryCode?: string;
        countryDialCode?: string;
    };
    eventDate: Date;
    extended: boolean;
    missingFields: string[];
    number: number;
    isAdult: boolean;
}

// get dropdown input options for countries and nationalities
const getNationalityOptions = () =>
    nationalities.map((n) => ({
        label: n.name,
        value: n.code,
    }));

const getHighlightedNationalityOptions = (): SelectOption[] =>
    getHighlightedNationalities(detailsHighlightedNationalities).map((n) => ({
        label: n.name,
        value: n.code,
    }));

const mapNationalitiesToDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedNationalityOptions(),
    },
    {
        groupName: 'All',
        options: getNationalityOptions(),
    },
];

const getCountryOptions = (): SelectOption[] =>
    countries.map((c) => ({
        label: c.name,
        value: c.code,
    }));

const getHighlightedCountryOptions = (): SelectOption[] =>
    getHighlightedCountries(detailsHighlightedNationalities).map((n) => ({
        label: n.name,
        value: n.code,
    }));

const mapCountriesToDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedCountryOptions(),
    },
    {
        groupName: 'All',
        options: getCountryOptions(),
    },
];

const getHighlightedCountryDialOptions = (): SelectOption[] =>
    getHighlightedCountries(detailsHighlightedNationalities).map((n) => ({
        label: `${n.name} ${n.dialCode}`,
        value: n.code,
    }));

const getCountryDialOptions = (): SelectOption[] =>
    countries.map((c) => ({
        label: `${c.name} ${c.dialCode}`,
        value: c.code,
    }));

const mapCountriesDialDropdownOptions = (): OptionsListItem[] => [
    {
        groupName: 'Most selected',
        options: getHighlightedCountryDialOptions(),
    },
    {
        groupName: 'All',
        options: getCountryDialOptions(),
    },
];

function numberToTwoDigitNumberString(num: number): string | null {
    if (!num || Number.isNaN(Number(num))) return null;

    if (num > 9) return num.toString();

    return `0${num.toString()}`;
}

const DetailsTraveler: React.FunctionComponent<Props> = ({
    bookOther = false,
    booker = false,
    cache,
    callback = () => {},
    eventDate,
    extended = false,
    missingFields,
    number,
    isAdult,
}) => {
    const { t } = useTranslation();
    const phoneNumberMaxLength = 15;

    const defaultState = {
        countryCode: cache && cache.countryCode,
        birthday: cache && cache.birthday,
        email: cache && cache.email,
        firstName: cache && cache.firstName,
        lastName: cache && cache.lastName,
        phoneNumber: cache && cache.phoneNumber,
        nationality: cache && cache.nationality,
        countryDialCode: cache && cache.countryDialCode,
    };

    const isInitialMount = React.useRef(true);
    const [countryCode, setCountryCode] = React.useState<string | undefined>(
        defaultState.countryCode
    );
    const [nationality, setNationality] = React.useState<string | undefined>(
        defaultState.nationality
    );
    const [countryDialCode, setCountryDialCode] = React.useState<string | undefined>(
        defaultState.countryDialCode
    );
    const [birthday, setBirthday] = React.useState<string | undefined>(defaultState.birthday);
    const [email, setEmail] = React.useState<string | undefined>(defaultState.email);
    const [emailErrors, setEmailErrors] = React.useState<VerifyEmailResponse | undefined>();
    const [firstName, setFirstName] = React.useState<string | undefined>(defaultState.firstName);
    const [lastName, setLastName] = React.useState<string | undefined>(defaultState.lastName);
    const [phoneNumber, setphoneNumber] = React.useState<string | undefined>(
        defaultState.phoneNumber
    );

    const handleDateOfBirthChange = (id: number, day: number, month: number, year: number) => {
        if (!checkIfValidDate(day, month, year, null)) {
            setBirthday(undefined);
        } else {
            setBirthday(
                [numberToTwoDigitNumberString(day), numberToTwoDigitNumberString(month), year].join(
                    '-'
                )
            );
        }
    };

    const verifyEmail = (email: string) => {
        return postVerifyEmail(email).then((result) => setEmailErrors(result));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (email) verifyEmail(email);
        }, 750);

        return () => clearTimeout(timer);
    }, [email]);

    const setValueForName = (name: string, value: string | undefined) => {
        let fieldWasIngored = false;

        switch (name) {
            case 'birth_date':
                setBirthday(value);
                break;
            case 'nationality':
                if (!value) break;

                setNationality(value);
                break;
            case 'country_code':
                if (!value) break;

                setCountryCode(value);

                if (!phoneNumber) {
                    setCountryDialCode(value);
                }
                break;
            case 'country_dial_code':
                setCountryDialCode(value);
                break;
            case 'email':
                setEmailErrors(undefined);
                setEmail(value);
                break;
            case 'first_name':
                setFirstName(value);
                break;
            case 'last_name':
                setLastName(value);
                break;
            case 'phone_number':
                setphoneNumber(value);
                break;
            default:
                fieldWasIngored = true;
                break;
        }

        if (!fieldWasIngored) {
            Cache.setUserStartedEnteringDetails(true);
        }
    };

    const handleFormFieldChange = ({ name = '', value = '' }) => {
        setValueForName(name, value);
    };

    const isFieldMissing = (fieldName: string) => missingFields?.indexOf(fieldName) >= 0;

    // When value of form changes
    React.useEffect(() => {
        // Don't call the callback on mount because it will overwrite the cache
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        callback({
            countryCode,
            birthday,
            email,
            firstName,
            lastName,
            phoneNumber,
            nationality,
            countryDialCode,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        countryCode,
        birthday,
        email,
        firstName,
        lastName,
        phoneNumber,
        nationality,
        countryDialCode,
    ]);

    const { day, month, year } = seperateDate(birthday);

    const getPhoneNumberMaxLength = () => {
        if (!countryDialCode) return phoneNumberMaxLength;

        return phoneNumberMaxLength - countryDialCode.replace('+', '').length;
    };

    const handlePhoneNumberChange = (value: string) => {
        handleFormFieldChange({
            name: 'phone_number',
            value,
        });
    };

    const handleDialCodeChange = (value: string) => {
        handleFormFieldChange({
            name: 'country_dial_code',
            value,
        });
    };

    const getBookerName = (number: number) => {
        if (booker && bookOther) return t('details_customerdetails');

        if (number === 0) return t('details_main_traveller');

        return t('details_traveler', { traveler: number + 1 });
    };

    const renderSuggestions = () => {
        if (!emailErrors) return;

        const suggestions = emailErrors.suggestions;

        return suggestions?.map((s, i) => {
            if (s === null) return;

            if (i === suggestions.length - 1)
                return (
                    <span
                        className={$.suggestion}
                        onClick={() => {
                            setEmailErrors(undefined);
                            setEmail(s);
                        }}
                    >
                        {s}
                    </span>
                );

            return (
                <span
                    className={$.suggestion}
                    onClick={() => {
                        setEmailErrors(undefined);
                        setEmail(s);
                    }}
                >
                    {s},
                </span>
            );
        });
    };

    const renderEmailErrors = () => {
        if (!emailErrors) return;

        if (emailErrors.certainly_wrong) {
            return <FormErrorMessage>{t('details_email_invalid')}</FormErrorMessage>;
        }

        if (emailErrors.possibly_wrong && emailErrors.suggestions?.length === 0) {
            return <FormErrorMessage>{t('details_email_possibly_invalid')}</FormErrorMessage>;
        }

        if (emailErrors.possibly_wrong && emailErrors.suggestions?.length > 0) {
            return (
                <>
                    <FormErrorMessage>
                        {t('details_email_possibly_invalid_with_suggestions')} {renderSuggestions()}
                    </FormErrorMessage>
                </>
            );
        }
    };

    return (
        <section>
            <Heading variant="h2">{getBookerName(number)}</Heading>
            <div className={$.whiteBlock}>
                <section className={$.formSection}>
                    {booker || (!bookOther && number === 0) ? (
                        <Heading variant="h3" grey marginTop={false}>
                            {t('details_passport')}
                        </Heading>
                    ) : undefined}
                    <div className={$.multi}>
                        <div className={$.field}>
                            <FormInput
                                label={t('details_firstname')}
                                size={FormInputSize.Medium}
                                onChange={(e) => {
                                    handleFormFieldChange({
                                        name: 'first_name',
                                        value: e.target.value,
                                    });
                                }}
                                showErrorMessage={isFieldMissing('firstName')}
                                defaultValue={firstName}
                                requiredErrorMessage={t('traveldetail_error_required_first_name')}
                                required
                            />
                        </div>
                        <div className={$.field}>
                            <FormInput
                                label={t('details_lastname')}
                                size={FormInputSize.Medium}
                                onChange={(e) => {
                                    handleFormFieldChange({
                                        name: 'last_name',
                                        value: e.target.value,
                                    });
                                }}
                                showErrorMessage={isFieldMissing('lastName')}
                                defaultValue={lastName}
                                requiredErrorMessage={t('traveldetail_error_required_last_name')}
                                required
                            />
                        </div>
                    </div>
                    <div className={$.field}>
                        <FormLabel>{t('details_nationality')}*</FormLabel>
                        <FormSelect
                            options={mapNationalitiesToDropdownOptions()}
                            onChange={(value) => {
                                handleFormFieldChange({
                                    name: 'nationality',
                                    value: value,
                                });
                            }}
                            smallHeight
                            value={nationality}
                            placeholder={t('form_select_nationality_placeholder')}
                        />
                    </div>
                    <div className={$.field}>
                        <FormLabel>{t('details_birthdate')}*</FormLabel>
                        <FormDateSelect
                            onChange={handleDateOfBirthChange}
                            id={number}
                            day={day}
                            eventDate={eventDate}
                            month={month}
                            year={year}
                            showErrorMessage={isFieldMissing('birthday')}
                            required
                            isAdult={isAdult}
                            isChild={!isAdult}
                        />
                    </div>

                    <div className={classnames($.field, $.dropdown)}>
                        <FormLabel>{t('details_countrycode')}*</FormLabel>
                        <FormSelect
                            options={mapCountriesToDropdownOptions()}
                            onChange={(value) => {
                                handleFormFieldChange({
                                    name: 'country_code',
                                    value: value,
                                });
                            }}
                            smallHeight
                            value={countryCode}
                            placeholder={t('form_select_country_placeholder')}
                        />
                    </div>
                    <hr className={$.line} />
                </section>
                {extended && (
                    <section className={$.formSection}>
                        <Heading variant="h3" grey marginTop={false}>
                            {t('details_contact')}
                        </Heading>

                        <div className={$.field}>
                            <FormInput
                                label={t('details_email')}
                                size={FormInputSize.Large}
                                transformValue={(value) => {
                                    return value.trim();
                                }}
                                onChange={(e) => {
                                    handleFormFieldChange({
                                        name: 'email',
                                        value: e.target.value,
                                    });
                                }}
                                showErrorMessage={isFieldMissing('email')}
                                pattern="^((([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$"
                                value={email}
                                requiredErrorMessage={t('traveldetail_error_required_email')}
                                required
                            />
                            {renderEmailErrors()}
                        </div>
                        <div className={$.field}>
                            <PhoneNumberSelect
                                options={mapCountriesDialDropdownOptions()}
                                value={countryDialCode || ''}
                                phoneNumberValidation={(value) => value.trim().replace(/\D/g, '')}
                                onChangeDialCode={handleDialCodeChange}
                                onChange={handlePhoneNumberChange}
                                phoneNumber={phoneNumber}
                                dialCode={countryDialCode || ''}
                                showErrorMessage={
                                    isFieldMissing('phoneNumber') ||
                                    isFieldMissing('countryDialCode')
                                }
                                errorPhoneNumberMessage={t(
                                    'traveldetail_error_required_phone_number'
                                )}
                                errorCountryDialCodeMessage={t(
                                    'traveldetail_error_required_phone_country_dial_code'
                                )}
                                maxLength={getPhoneNumberMaxLength()}
                            />
                            <FormHintMessage message={t('details_phone_hint')} />
                        </div>
                    </section>
                )}
            </div>
        </section>
    );
};

export default DetailsTraveler;
