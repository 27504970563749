/* eslint-disable @typescript-eslint/camelcase */
import sumBy from 'lodash/sumBy';
import moment from 'moment-timezone';
import { shallowEqualArrays, shallowEqualObjects } from 'shallow-equal';
import { AppLocale, localizeOrigin } from 'src/app/constants/appLocale';
import { breakpoints } from 'src/app/constants/breakpoints';
import store from 'src/app/store';
import { selectOrder } from 'src/app/store/orderSlice';
import { getEnvironment } from 'src/app/utils/config';
import { PackageType } from 'src/data/models/Order';
import * as Cache from 'src/data/services/cache';
import { getOccupancy } from 'src/data/services/cache';

export function environment(): string {
    return getEnvironment('REACT_APP_ENV') || 'local';
}

export function generateId(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substring(2, 9);
}

export function isMobile() {
    return window.innerWidth < breakpoints.medium;
}

export function isTablet() {
    return window.innerWidth < breakpoints.large && window.innerWidth >= breakpoints.small;
}

export const isInE2ETestingEnvironment = !!window.Cypress;

export function isSmallMobile() {
    return window.innerWidth < 700;
}

export function parseLink(string: string, external = false): string {
    let newString = string;
    // get all links from a string (both name and src)
    const matches = [...string.matchAll(/(?:<a href=")(.*?)">(.*?)<\/a>/gm)];
    const target = external ? '_blank' : '_self';

    if (matches.length > 0) {
        matches.forEach((item) => {
            newString = newString.replace(
                item[0],
                `<a href="${item[1]}" title="${item[2]}" target="${target}">${item[2]}</a>`
            );
        });
    }

    return newString;
}

export function checkIfValidDate(day, month, year, compareDate) {
    if (!day || !month || !year) return false;
    if (year.toString().length !== 4) return false;

    const minimumBirthDate = new Date();
    // Infants are allowed to travel by plane when they're 8 days old
    minimumBirthDate.setDate(minimumBirthDate.getDate() - 8);

    if (day > 31 || month > 12 || year > minimumBirthDate.getFullYear()) return false;

    // month needs to be subtracted by 1 since January === 0 and December === 11
    const birthDate = new Date(year, month - 1, day);
    minimumBirthDate.setMonth(minimumBirthDate.getMonth() + 1);
    if (birthDate < minimumBirthDate) {
        return true;
    }

    return false;
}

export function getAgeOnDate(day, month, year, compareDate) {
    if (!checkIfValidDate(day, month, year, compareDate)) return false;

    const birthday = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY');

    return compareDate.diff(moment(birthday), 'years');
}

export function checkIfChild(day, month, year, compareDate) {
    const age = getAgeOnDate(day, month, year, compareDate);
    if (!age) return false;
    return age >= 0 && age < 18;
}

export function checkIfAdult(day, month, year, compareDate) {
    const age = getAgeOnDate(day, month, year, compareDate);

    return age >= 18;
}

export function capitalize(text) {
    return text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

// dd-mm-yyyy
const dateStringRegex = new RegExp(/\d{1,2}-\d{1,2}-\d{4}/);
const reverseStringRegex = new RegExp(/\d{4}-\d{1,2}-\d{1,2}/);

function isDateString(str) {
    return dateStringRegex.test(str);
}

function isReverseDateString(str) {
    return reverseStringRegex.test(str);
}

export function seperateDate(date) {
    if (!date || typeof date !== 'string' || (!isDateString(date) && !isReverseDateString(date))) {
        return { day: undefined, month: undefined, year: undefined };
    }
    if (isReverseDateString(date)) {
        const [year, month, day] = date.split('-').map((d) => Number(d));
        return { day, month, year };
    }
    const [day, month, year] = date.split('-').map((d) => Number(d));
    return { day, month, year };
}

export function getReversedDate(birthday: string | undefined) {
    if (
        !birthday ||
        typeof birthday !== 'string' ||
        (!isDateString(birthday) && !isReverseDateString(birthday))
    ) {
        return '';
    }

    return moment(birthday, 'D-M-YYYY').format('YYYY-MM-DD');
}

export function queryStringToObject(qs: string) {
    const sanitizedQueryString = qs.charAt(0) === '?' ? qs.substring(1) : qs;

    if (sanitizedQueryString.length === 0) {
        return {};
    }

    const params = new URLSearchParams(sanitizedQueryString);
    return Object.fromEntries(params);
}

export function getTripDate(startDate, endDate) {
    const isSameMonth = endDate.month() === startDate.month();
    const startDateFormat = isSameMonth ? 'D' : 'D MMM';

    return `${startDate.format(startDateFormat)} - ${endDate.format('D MMM YYYY')}`;
}

export function createDebounce(amount: number) {
    let timeoutId: any = null;

    /**
     * Calls the passed function only when this function is not called again
     * within a predetermined amount of time.
     * @param {Function} cb
     */
    const debounce = (cb: any) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            timeoutId = null;
            cb();
        }, amount);
    };

    return debounce;
}

export function getElementCoordinates(el) {
    let xPos = 0;
    let yPos = 0;

    if (el.tagName === 'BODY') {
        // deal with browser quirks with body/window/document and page scroll
        const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        const yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += el.offsetLeft - xScroll + el.clientLeft;
        yPos += el.offsetTop - yScroll + el.clientTop;
    } else {
        // for all other non-BODY elements
        xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPos += el.offsetTop - el.scrollTop + el.clientTop;
    }

    return {
        x: xPos,
        y: yPos,
    };
}

export function getValuesFromObject<T>(obj: Record<string, T[]>): T[] {
    return Object.values(obj).flatMap((value) => value);
}

export function addValueToArray<T = string>(
    arr: T[],
    value: T,
    existsPredicate = (arrayValue: T, value: T) => arrayValue === value
): T[] {
    const isValuePresent = arr.some((v) => existsPredicate(v, value));
    return isValuePresent ? arr : [...arr, value];
}

export function addValuesToArray<T = string>(
    values: T[],
    arr: T[],
    existsPredicate = (arrayValue: T, value: T) => arrayValue === value
): T[] {
    return values.reduce(
        (newArr, value) => addValueToArray(newArr, value, existsPredicate),
        [...arr]
    );
}

export function removeValueFromArray<T = string>(
    value: T,
    arr: T[],
    findPredicate?: (arrayValue: T, value: T) => boolean
) {
    const valueIndex = arr.findIndex((e) => findPredicate?.(e, value) || e === value);

    if (valueIndex !== -1) {
        return [...arr.slice(0, valueIndex), ...arr.slice(valueIndex + 1, arr.length)];
    }
    return arr;
}

export function flat(nestedArr: any[][]): any[] {
    if (nestedArr.length === 0) return [];
    const mutableNestedArr = [...nestedArr];

    return mutableNestedArr.reduce((prevArr, arrEl) => {
        const unnestedArr = prevArr;

        if (arrEl.constructor === Array) arrEl.forEach((value) => unnestedArr.push(value));
        else unnestedArr.push(arrEl);

        return unnestedArr;
    }, []);
}

export class OrderTypeUtil {
    public static hasHotel(packageType: PackageType) {
        return (
            packageType === PackageType.TICKET_HOTEL ||
            packageType === PackageType.TICKET_HOTEL_FLIGHT
        );
    }

    public static hasFlight(packageType: PackageType) {
        return packageType === PackageType.TICKET_HOTEL_FLIGHT;
    }

    public static onlyHasTicket(packageType: PackageType) {
        return packageType === PackageType.TICKET_ONLY;
    }
}

export function checkIfNestedArrIsEqual(arr: any[], cacheArr: any[], nestedObj = false) {
    if (arr === null && cacheArr === null) return true;
    if (arr !== null && cacheArr === null) return false;
    if (arr.length !== cacheArr.length) return false;

    return !arr
        .map((nested, i) => {
            if (nestedObj) {
                return shallowEqualObjects(nested, cacheArr[i]);
            }
            return shallowEqualArrays(nested, cacheArr[i]);
        })
        .includes(false);
}

export function isWithinTheLeadTimeDays(eventDate: Date, leadTimeDays: number): boolean {
    const remainingDaysToEvent =
        (new Date(eventDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24);
    return remainingDaysToEvent <= leadTimeDays;
}

export function getPriceByPercentage(basePrice: number, percentage: number): number {
    const price: number = (basePrice * percentage) / 100;
    return price;
}

function isValidReferrerUrl(url: string, validWebsites: string[]): boolean {
    for (let i = 0; i < validWebsites.length; i += 1) {
        if (url.startsWith(validWebsites[i])) return true;
    }

    return false;
}

export function retrievePartnerWebsiteUrl(
    referrerWebsiteUrl: string | null,
    validWebsites: string[],
    websiteUrl: string | null
): URL | null {
    // Option 1: The referrer is known and recognized as a valid website url.
    if (
        referrerWebsiteUrl &&
        referrerWebsiteUrl.length > 0 &&
        isValidReferrerUrl(referrerWebsiteUrl, validWebsites)
    ) {
        // Write it to cache for possible later usage.
        Cache.setPartnerReferrerUrl(referrerWebsiteUrl);
        return new URL(referrerWebsiteUrl);
    }

    const cachedReferrer = Cache.getPartnerReferrerUrl();
    // Option 2: There's no referrer, but we do have one in cache and it's also still valid for the current partner.
    if (cachedReferrer && isValidReferrerUrl(cachedReferrer, validWebsites)) {
        return new URL(cachedReferrer);
    }

    // Option 3: There's no referrer at all, fall back to just the partners' websiteUrl.
    if (websiteUrl) {
        return new URL(localizeOrigin(websiteUrl));
    }

    return null;
}

export function localeToLanguageAndCountry(locale: AppLocale): {
    language: string;
    country: string;
} {
    const [language, country] = locale.split('-');

    return {
        language: language || '',
        country: locale === 'en' ? 'US' : country || language?.toUpperCase() || '',
    };
}

export const formatPercentage = (percentage: number): string =>
    Number(percentage / 100).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
    });

export const enumToStringArray = (e): string[] =>
    Object.keys(e).filter((item) => isNaN(Number(item)));

export const getTravellerOccupancy = () => {
    const order = selectOrder(store.getState());
    const { packageType } = { ...order };
    const { roomLayout, adults } = getOccupancy();

    if (packageType && OrderTypeUtil.hasHotel(packageType)) {
        const roomAdults = sumBy(roomLayout, (r) => r.adults);
        const roomChildren = sumBy(roomLayout, (r) => r.children);

        return {
            adults: roomAdults,
            children: roomChildren,
            roomLayout,
        };
    }

    return {
        adults: adults.length,
        children: adults.length,
        roomLayout,
    };
};
