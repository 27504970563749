import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { localizeOrigin, type AppLocale } from 'src/app/constants/appLocale';
import MobileContext from 'src/app/context/MobileContext';
import { formatTicketDeliveryString } from 'src/app/helpers/formatTicketDeliveryString';
import { mapAvailabilityErrorMessage } from 'src/app/mappers/availability/error';
import {
    getTicketTypesTranslation,
    hasPickupAtVenue,
} from 'src/app/mappers/ticketTypes/mapTicketTypesTranslation';
import { RootState, TypedDispatch } from 'src/app/store';
import {
    ExperimentVariantType,
    initializeSession,
    resetSession,
    selectInitializedSession,
    selectPartnerConfig,
    selectSession,
    Session,
    setSessionOrder,
} from 'src/app/store/appSlice';
import {
    EventStateStatus,
    fetchEvent,
    selectEventDetail,
    selectEventStatus,
    selectSelectedTicketCategoryId,
    setSelectedTicket as setSelectedTicketOnEventSlice,
} from 'src/app/store/eventSlice';
import {
    AvailabilityErrorEnum,
    createOrder,
    OrderErrorStatus,
    OrderStatusEnum,
    pushTicketsSelection,
    selectAvailability,
    selectAvailabilityError,
    selectErrorAndStatus,
    selectOrder,
    selectOrderIsFetching,
    selectSelectedTicket,
    setSelectedTicket,
    updateOrderPreferences,
} from 'src/app/store/orderSlice';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { setLastKnownEntryPoint } from 'src/app/utils/lastKnownEntryPointHelper';
import { getTargetPackageType, PackageTypeSource } from 'src/app/utils/packageTypeDecider';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { addValueToArray, isMobile, isTablet, OrderTypeUtil } from 'src/app/utils/utils';
import { Availability } from 'src/data/models/Availability';
import { AvailabilityBasePackage } from 'src/data/models/AvailabilityBaseAccommodation';
import { BasePackage } from 'src/data/models/BaseAccommodation';
import { Config } from 'src/data/models/Config';
import { EventDetail } from 'src/data/models/EventDetail';
import { EventTicket } from 'src/data/models/EventTicket';
import { Order as OrderModel, PackageType } from 'src/data/models/Order';
import { Preferences } from 'src/data/models/Preferences';
import { Ticket as TicketModel } from 'src/data/models/Ticket';
import * as Cache from 'src/data/services/cache';
import { formatMoney } from 'src/data/services/formatting';
import { OrderBaseDetails, RoomOccupancy } from 'src/data/services/order';
import { getMicroCopyVariant } from 'src/view/ABComponents/TCO504MicroCopyText/TCO504MicroCopyText';
import {
    ErrorMessage,
    InfoMessage,
    NextStepBanner,
    Page,
    SeatPicker,
    Spinner,
} from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import Card, { CardBody } from 'src/view/components/Card';
import ConfirmButton from 'src/view/components/ConfirmButton/ConfirmButton';
import { Heading } from 'src/view/components/Heading/Heading';
import Notification from 'src/view/components/Notification/Notification';
import { OrderPreferences } from 'src/view/components/OrderPreferences/OrderPreferences';
import { VSpacer } from 'src/view/components/Page';
import SeatSwiperPicker from 'src/view/components/SeatPicker/SeatSwiperPicker';
import { NotFound } from '../NotFound/NotFound';
import $ from './Ticket.module.scss';

interface TicketRouteParams {
    eventId: string;
    locale?: AppLocale;
}

interface Props extends WithTranslation, RouteComponentProps<TicketRouteParams> {
    config: Config | null;
    dispatch: TypedDispatch<RootState>;
    eventStatus: EventStateStatus;
    eventDetail: EventDetail | null;
    session: Session | null;
    initializedSession: boolean;
    order: OrderModel | null;
    orderIsFetching: boolean;
    availability: Availability | null;
    availabilityError: AvailabilityErrorEnum;
    orderError: OrderErrorStatus | null;
    eventSelectedTicketCategoryId: string | null;
    orderSelectedTicket: TicketModel | null;
    microCopyVariant?: ExperimentVariantType;
}

interface State {
    availability?: Availability;
    errors: string[];
    loadingNextStep: boolean;
    packageType: PackageType;
    orderUpToDate: boolean;
    preferencesConfirmed: boolean;
    ticket?: TicketModel;
    areBirthdaysValid: boolean;
    priceChanged: boolean;
    showChangedPackageTypeMessage: boolean;
    changedPackageType?: PackageType | null;
    changedPackageTypeBecauseNotAvailable: boolean;
    isSubmitting: boolean;
    userChangedSeatSelection: boolean;
}

const sortByPrice = (arr: TicketModel[]) => {
    return [...arr].sort((a, b) => a.supplementPP - b.supplementPP);
};

class Ticket extends React.Component<Props, State> {
    public maxOccupancy = 10;
    public confirmButtonRef;

    public constructor(props: Props) {
        super(props);
        this.confirmButtonRef = React.createRef<HTMLDivElement>();

        this.state = {
            errors: [],
            packageType: PackageType.TICKET_ONLY,
            orderUpToDate: true,
            preferencesConfirmed: false,
            areBirthdaysValid: false,
            priceChanged: false,
            showChangedPackageTypeMessage: false,
            changedPackageType: null,
            changedPackageTypeBecauseNotAvailable: false,
            loadingNextStep: false,
            isSubmitting: false,
            userChangedSeatSelection: false,
        };

        this.handleConfirm = this.handleConfirm.bind(this);
        this.checkIfOrderIsUpToDate = this.checkIfOrderIsUpToDate.bind(this);
        this.handleTickets = this.handleTickets.bind(this);
        this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
    }

    public async componentDidMount() {
        const { dispatch } = this.props;
        const { eventId } = this.props.match.params;

        // Set the last known entry point, so we can go back to here when something goes wrong.
        setLastKnownEntryPoint(window.location.href);

        dispatch(
            initializeSession(eventId, {
                baseTicketCategoryId: RouteHelper.getCategoryIdFromUrl(),
                preferredPackageType: RouteHelper.getPackageTypeFromUrl() || undefined,
            })
        );

        const { eventDetail, session, initializedSession, order, availability } = this.props;

        if (order && initializedSession) {
            dispatch(setSessionOrder({ orderId: order.id, orderSecret: order.secret }));
        }

        if (eventDetail && initializedSession) {
            this.handleIncomingEventDetail(eventDetail);
        }

        if (session && initializedSession) {
            this.handleIncomingSession(session);
        }

        if (availability && initializedSession) {
            this.handleIncomingAvailability(availability);
        }

        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'package',
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const { eventDetail, session, initializedSession, order, availability, dispatch } =
            this.props;

        if (order && prevProps.order?.id !== order.id && prevProps.order?.secret !== order.secret) {
            dispatch(setSessionOrder({ orderId: order.id, orderSecret: order.secret }));
        }

        if (eventDetail && initializedSession && prevProps.eventDetail?.id !== eventDetail.id) {
            this.handleIncomingEventDetail(eventDetail);
        }

        const sessionChanged =
            prevProps.initializedSession !== initializedSession ||
            prevProps.session?.id !== session?.id;

        if (initializedSession && session && sessionChanged) {
            this.handleIncomingSession(session);
        }

        if (
            initializedSession &&
            availability &&
            availability.hash !== prevProps.availability?.hash
        ) {
            this.handleIncomingAvailability(availability);
        }
    }

    private scrollToPositionFromTop(top: number) {
        document.body.scrollTo({
            top,
            behavior: 'smooth',
        });
    }

    private handleIncomingAvailability(availability: Availability) {
        const baseTicket = this.getBaseTicket(availability.tickets, availability.basePackage);
        const selectedTicketCategoryId = this.getActiveTicketCategoryId();

        const selectedTicketInAvailability = selectedTicketCategoryId
            ? availability.tickets.find((ticket) => ticket.categoryId === selectedTicketCategoryId)
            : null;

        const ticketToSelect = selectedTicketInAvailability || baseTicket;

        if (ticketToSelect) {
            this.setTicket(ticketToSelect.categoryId);
        }

        if (!this.confirmButtonRef.current) return;

        const position = this.confirmButtonRef.current.getBoundingClientRect();
        const desktopScrollToPosition = position.y - 100;
        const tabletScrollToPosition = position.y - 150;
        const mobileScrollToPosition = position.y - 170;

        const getScrollPosition = () => {
            if (isMobile()) return mobileScrollToPosition;

            if (isTablet()) return tabletScrollToPosition;

            return desktopScrollToPosition;
        };

        this.scrollToPositionFromTop(getScrollPosition());
    }

    private handleIncomingSession(session: Session) {
        const { dispatch } = this.props;

        dispatch(fetchEvent(session.eventId, session.baseTicketCategoryId));
    }

    private setPackageTypeState = (eventDetail: EventDetail) => {
        const { order } = this.props;

        const targetPackageType = getTargetPackageType(
            RouteHelper.getPackageTypeFromUrl(),
            order?.packageType
        );

        let { packageType } = targetPackageType;
        let downgradedPackageType = false;
        let oldPackageType = targetPackageType.overwrittenPackageType;

        if (!eventDetail.offersPackageType(targetPackageType.packageType)) {
            packageType =
                eventDetail.basePackage?.getCheapestAvailablePackageType() ||
                PackageType.TICKET_ONLY;
            oldPackageType = targetPackageType.packageType;
            downgradedPackageType = true;
        }

        const packageTypeChanged =
            targetPackageType.overwritesCache &&
            targetPackageType.overwrittenPackageType !== packageType;

        // When the user already started entering details
        const shouldNotifyUserOfChangedPackageType =
            Cache.getUserStartedEnteringDetails() &&
            targetPackageType.source === PackageTypeSource.Hotlink;

        if (shouldNotifyUserOfChangedPackageType && (downgradedPackageType || packageTypeChanged)) {
            this.setState({
                showChangedPackageTypeMessage: true,
                changedPackageType: oldPackageType,
                changedPackageTypeBecauseNotAvailable: downgradedPackageType,
            });
        }

        this.setState({ packageType: order?.packageType || packageType });
    };

    private handleNextStepClicked = async () => {
        const { history, t, session, order, availability, dispatch } = this.props;
        const { orderUpToDate, areBirthdaysValid, packageType } = this.state;
        const selectedTicket = this.getActiveTicketCategoryId();

        if (!order || !selectedTicket || !session || !availability) return;

        if (orderUpToDate) {
            this.setState({ loadingNextStep: true });

            try {
                await dispatch(pushTicketsSelection());
            } catch (err) {
                this.setState({ loadingNextStep: false });

                return;
            }

            pushStepEventToDataLayer({
                event_type: 'step_complete',
                step_name: 'seating',
            });

            if (OrderTypeUtil.hasHotel(packageType)) {
                history.push(RouteHelper.getHotelRoute(session.eventId));

                return;
            }

            if (OrderTypeUtil.onlyHasTicket(packageType)) {
                history.push(RouteHelper.getDetailsRoute(session.eventId));
            }

            return;
        }

        const { errors } = this.state;

        if (areBirthdaysValid) {
            this.setState({
                errors: addValueToArray(errors, t('ticket_preferencesnotconfirmed')),
            });
        } else {
            this.setState({
                errors: addValueToArray(errors, t('ticket_childrenagenotvalid')),
            });
        }
    };

    private setTicket = (categoryId: string) => {
        const { availability, dispatch } = this.props;

        if (!availability) {
            dispatch(setSelectedTicketOnEventSlice(categoryId));

            return;
        }

        const ticket = availability.findTicketByCategoryId(categoryId);

        if (!ticket) {
            return;
        }

        dispatch(setSelectedTicket(ticket.id));
    };

    private refreshOrderWithPreferencesUpdate = async (
        updatePreferences: boolean,
        preferences: Preferences
    ) => {
        const orderIsUpToDate = this.checkIfOrderIsUpToDate(preferences);

        this.setState({ isSubmitting: true });

        await this.createOrderWithPreferencesUpdate(updatePreferences, preferences);

        this.setState({
            isSubmitting: false,
            orderUpToDate: orderIsUpToDate,
            preferencesConfirmed: orderIsUpToDate,
        });
    };

    private createOrderWithPreferencesUpdate = async (
        updatePreferences: boolean,
        preferences: Preferences
    ) => {
        const { eventDetail, session, order, dispatch } = this.props;
        const { eventId } = this.props.match.params;
        const { packageType } = this.state;

        // No event detail, so it shouldn't create an order
        if (!eventId) return;

        // There is an event detail, but there is no accommodation package
        // so an order shouldn't be created and the 'not available' view will be rendered instead
        if (eventDetail && !eventDetail.basePackage) return;

        const newOrder: RoomOccupancy[] = [];

        const { adults, roomLayout } = preferences;

        if (OrderTypeUtil.hasHotel(packageType)) {
            roomLayout.forEach((rl) => {
                const childrenArray = rl.childInformation.map((c) => {
                    if (!c.dateOfBirth) return '';

                    const { day, month, year } = c.dateOfBirth;

                    return moment(`${day}-${month}-${year}`, 'D-M-YYYY').format('YYYY-MM-DD');
                });

                newOrder.push({ adults: rl.adults, children: childrenArray });
            });
        } else {
            newOrder.push({ adults: adults.length });
        }

        const { inDate: inAdd, outDate: outAdd } = preferences;

        const dateStart = eventDetail
            ? moment(eventDetail.dateMinimumStart).add(-outAdd, 'days').format('YYYY-MM-DD')
            : null;

        const dateEnd = eventDetail
            ? moment(eventDetail.dateMinimumEnd).add(inAdd, 'days').format('YYYY-MM-DD')
            : null;

        const orderDetails: OrderBaseDetails = {
            occupancy: newOrder,
            dateStart,
            dateEnd,
            packageType,
            baseTicketCategoryId: session?.baseTicketCategoryId,
        };

        // if order is in Cache and there are changes in the preferences we make a request to update the order with the updated preferences
        if (order && updatePreferences) {
            const isOrderUpToDate = this.checkIfOrderIsUpToDate(preferences);

            if (isOrderUpToDate) return;

            dispatch(updateOrderPreferences(orderDetails));
        }

        // if order is not in Cache, we create a new order after confirming the preferences
        if ((!session?.orderId || !order || session?.orderId !== order?.id) && updatePreferences) {
            dispatch(createOrder(eventId, orderDetails));
        }
    };

    private getBaseTicket(
        tickets: EventTicket[] | TicketModel[],
        basePackage: BasePackage | AvailabilityBasePackage
    ): TicketModel | EventTicket | undefined {
        const categoryId = RouteHelper.getCategoryIdFromUrl();
        let baseTicket;

        // use category_id from url to set the selected ticket option
        if (categoryId) {
            baseTicket = tickets.find(
                (ticket: TicketModel | EventTicket) => ticket.categoryId === categoryId
            );
        }

        // if baseTicket is still undefined use basePackage to set the selected ticket option
        if (!baseTicket) {
            baseTicket = tickets.find(
                (ticket: TicketModel | EventTicket) => ticket.categoryId === basePackage.categoryId
            );
        }

        return baseTicket;
    }

    private handleIncomingEventDetail = (eventDetail: EventDetail) => {
        const { availability } = this.props;
        const { eventId } = this.props.match.params;

        if (eventDetail.id !== eventId) return;

        this.setPackageTypeState(eventDetail);

        if (!availability && eventDetail.basePackage) {
            const ticketToSelect = this.getBaseTicket(eventDetail.tickets, eventDetail.basePackage);

            if (ticketToSelect) {
                this.setTicket(ticketToSelect.categoryId);
            }
        }
    };

    private getPackageTypeString = (type?: PackageType | null): string => {
        if (type) {
            if (type === PackageType.TICKET_ONLY) return 'ticket only';

            if (type === PackageType.TICKET_HOTEL) return 'ticket + hotel';
        }

        return '';
    };

    public async handleConfirm(preferences: Preferences) {
        const { packageType } = this.state;
        const { adults, outDate, inDate, roomLayout } = preferences;

        this.refreshOrderWithPreferencesUpdate(true, preferences).then(() => {
            this.setState({
                orderUpToDate: true,
                preferencesConfirmed: true,
            });
        });

        if (OrderTypeUtil.hasHotel(packageType)) {
            Cache.setRoomLayout(roomLayout);
            Cache.setInDate(inDate);
            Cache.setOutDate(outDate);
        } else {
            Cache.setAdults(adults);
        }
    }

    public async handleTickets(categoryId: string) {
        const { eventDetail } = this.props;

        this.setState({
            userChangedSeatSelection: true,
        });

        if (!eventDetail) return;

        this.setTicket(categoryId);
        this.setState({});
    }

    public async handleOrderTypeChange(value: string, preferences: Preferences) {
        const { order } = this.props;

        this.setState({ packageType: value as PackageType }, () => {
            if (order) {
                const orderUpToDate = this.checkIfOrderIsUpToDate(preferences);

                this.setState({
                    orderUpToDate,
                    errors: [],
                    // toggle preferences button based on the value of orderUpToDate
                    preferencesConfirmed: orderUpToDate,
                });
            }
        });
    }

    public checkIfOrderIsUpToDate(preferences: Preferences): boolean {
        const { packageType } = this.state;
        const { order, availability } = this.props;

        if (!order || !availability) return false;

        if (!preferences) return false;

        const category_id = RouteHelper.getCategoryIdFromUrl() || null; // Return null so it doesn't compare undefined to null (order.baseTicketCatId)

        if (category_id !== order.baseTicketCatId) return false;

        const { roomLayout: cacheRoomLayout, adults: cacheAdults } = Cache.getOccupancy();

        const { outDate: cacheOutDate, inDate: cacheInDate } = Cache.getInOutDate();
        const { adults, outDate, inDate, roomLayout } = preferences;

        let isRoomLayoutEqual = true;

        if (cacheRoomLayout?.length) {
            isRoomLayoutEqual = isEqual(roomLayout, cacheRoomLayout);
        }

        return (
            adults.length === cacheAdults.length &&
            outDate === cacheOutDate &&
            inDate === cacheInDate &&
            packageType === order.packageType &&
            isRoomLayoutEqual
        );
    }

    private getTickets = (): TicketModel[] => {
        const { availability, eventDetail } = this.props;

        if (availability) {
            return availability.tickets.map((t) => {
                const eventTicket =
                    eventDetail?.tickets.find((et) => et.categoryId === t.categoryId) || null;

                return new TicketModel({
                    id: t.id,
                    category_id: t.categoryId,
                    name: t.name,
                    seatplan_image: t.seatplanImage,
                    supplement_pp: t.supplementPP,
                    description: t.description || eventTicket?.description || undefined,
                    images: [],
                    possibleTicketTypes: eventTicket?.possibleTicketTypes,
                });
            });
        }

        const eventTicketsLength = eventDetail?.tickets.length;

        if (eventTicketsLength && eventTicketsLength > 0) {
            return eventDetail?.tickets.map((eventTicket) => {
                const price = eventTicket.supplementPP[2] || 0; // TODO: Make dynamic

                return new TicketModel({
                    id: eventTicket.id,
                    category_id: eventTicket.categoryId,
                    name: eventTicket.name,
                    seatplan_image: eventTicket.seatplanImage,
                    supplement_pp: price,
                    description: eventTicket.description,
                    images: [],
                });
            });
        }

        return [];
    };

    private getActiveTicketCategoryId = (): string | null => {
        const { eventSelectedTicketCategoryId, availability, eventDetail, orderSelectedTicket } =
            this.props;

        if (!eventDetail?.basePackage) return null;

        if (orderSelectedTicket) return orderSelectedTicket.categoryId;

        if (eventSelectedTicketCategoryId) return eventSelectedTicketCategoryId;

        const baseTicket = this.getBaseTicket(
            this.getTickets(),
            availability ? availability.basePackage : eventDetail.basePackage
        );

        return baseTicket?.categoryId || null;
    };

    private urlRequestedCategoryIsAvailable = () => {
        const categoryId = RouteHelper.getCategoryIdFromUrl();
        if (!categoryId) return true;
        return this.getTickets().some((t) => t.categoryId === categoryId);
    };

    private findActiveCategoryById = () => {
        const activeTicketCategoryId = this.getActiveTicketCategoryId();
        return this.getTickets().find((t) => t.categoryId === activeTicketCategoryId);
    };

    public render() {
        const { t, availability, orderIsFetching, availabilityError, dispatch } = this.props;

        const {
            errors,
            packageType,
            orderUpToDate,
            preferencesConfirmed,
            priceChanged,
            showChangedPackageTypeMessage,
            changedPackageType,
            changedPackageTypeBecauseNotAvailable,
            loadingNextStep,
            userChangedSeatSelection,
        } = this.state;

        const { config, eventStatus, eventDetail, session } = this.props;

        if (eventStatus === EventStateStatus.FETCHING || !session) {
            return (
                <Page step={1}>
                    <section className={$.spinnerWrap}>
                        <Spinner color="#222222" size={25} />
                    </section>
                </Page>
            );
        }

        if (eventStatus === EventStateStatus.ERROR) {
            return <NotFound />;
        }

        if (eventStatus === EventStateStatus.NOT_AVAILABLE) {
            const { websiteUrl } = config || {};
            const redirectToMatches = () => {
                if (!!websiteUrl) {
                    window.location.href = localizeOrigin(websiteUrl);
                }
            };

            return (
                <NotFound
                    title={t('ticket_no_accommodation_package_headline')}
                    text={t('ticket_no_accommodation_package_subtitle')}
                    onButtonClick={redirectToMatches}
                />
            );
        }

        if (!eventDetail) {
            return <Page step={1}>{t('ticket_eventnotfound')}</Page>;
        }

        let ticketHotelPrice = '0';
        let ticketPrice = '0';

        if (eventDetail.basePackage?.price.TICKET_HOTEL) {
            ticketHotelPrice = formatMoney(eventDetail.basePackage.price.TICKET_HOTEL, {
                hideZeroDecimals: true,
            });
        }

        if (eventDetail.basePackage?.price.TICKET_ONLY) {
            ticketPrice = formatMoney(eventDetail.basePackage.price.TICKET_ONLY, {
                hideZeroDecimals: true,
            });
        }

        const handleOnChange = (preferences: Preferences) => {
            const orderIsUpToDate = this.checkIfOrderIsUpToDate(preferences);

            this.setState({
                orderUpToDate: orderIsUpToDate,
                errors: [],
                // toggle preferences button based on the value of orderUpToDate
                preferencesConfirmed: orderIsUpToDate,
            });
        };

        const handlePackageTypeConfirm = () => {
            const { packageType } = this.state;
            const preferences = Cache.getPreferences();

            if (packageType) {
                this.setState({ packageType, showChangedPackageTypeMessage: false }, async () => {
                    await this.handleConfirm(preferences);
                });
            }
        };

        const handleClose = () => {
            this.setState({ showChangedPackageTypeMessage: false });
        };

        const readyForNextStep =
            orderUpToDate &&
            !loadingNextStep &&
            errors.length === 0 &&
            preferencesConfirmed &&
            availabilityError === AvailabilityErrorEnum.NONE;

        const availabilityErrorMessage = availabilityError !== AvailabilityErrorEnum.NONE && (
            <div className={$.availabilityErrorMessageWrapper} data-cy="ticket-availability-error">
                <ErrorMessage content={mapAvailabilityErrorMessage(availabilityError, t)} />
            </div>
        );

        const renderOrderError = () => {
            if (this.props.orderError?.status !== OrderStatusEnum.ERROR) return null;

            return (
                <div className={$.availabilityErrorMessageWrapper} data-cy="ticket-order-error">
                    <ErrorMessage content={t('order_create_error')} />
                    <InfoMessage
                        text={t(
                            'needHelpFinalizingOrder',
                            'Need help? Or having trouble finalizing your order? Press refresh or call us on +443308080559.'
                        )}
                    />
                    <Box mt={2}>
                        <Button
                            text="Refresh"
                            onClick={() => {
                                dispatch(resetSession);
                                location.reload();
                            }}
                        />
                    </Box>
                </div>
            );
        };

        const { microCopyVariant } = this.props;
        const confirmButtonText = microCopyVariant
            ? t('microCopyGeneralConfirmButtonText')
            : packageType === PackageType.TICKET_ONLY
            ? t('ticket_nextstep')
            : t('ticket_choose_hotel');

        return (
            <Page step={1} eventDetail={eventDetail} showPricing={orderUpToDate}>
                {/* This component is used so we can use the useCheckoutStepEvent hook */}
                <Card className="fullWidthCard">
                    <CardBody>
                        <div className={$.row}>
                            <Heading variant="h2" marginTop={false}>
                                1. {t('ticket_choose_preferences')}
                            </Heading>
                        </div>
                        <OrderPreferences
                            errors={errors}
                            onRefreshOrderWithPreferencesUpdate={(
                                updatePreferences: boolean,
                                preferences: Preferences
                            ) =>
                                this.refreshOrderWithPreferencesUpdate(
                                    updatePreferences,
                                    preferences
                                )
                            }
                            autoConfirmPreferences={false}
                            maxOccupancy={this.maxOccupancy}
                            onChange={handleOnChange}
                            isSubmitting={orderIsFetching}
                            ticketPrice={ticketPrice}
                            accommodationPrice={ticketHotelPrice}
                            packageType={packageType}
                            eventDetail={eventDetail}
                            preferencesConfirmed={
                                preferencesConfirmed &&
                                availabilityError === AvailabilityErrorEnum.NONE
                            }
                            handleOrderTypeChange={this.handleOrderTypeChange}
                            onConfirmPreferences={this.handleConfirm}
                            checkIfOrderIsUpToDate={this.checkIfOrderIsUpToDate}
                            priceChanged={priceChanged}
                        />

                        {availabilityErrorMessage}
                        {renderOrderError()}
                    </CardBody>
                </Card>

                {(!preferencesConfirmed || !availability) && (
                    <>
                        <VSpacer />
                        <div className={$.banner}>
                            <NextStepBanner text={`2. ${t('ticket_seats')}`} />
                        </div>
                    </>
                )}

                {preferencesConfirmed && availability && (
                    <div ref={this.confirmButtonRef}>
                        <VSpacer />
                        <Card className="fullWidthCard">
                            <CardBody>
                                <Heading variant="h2" marginTop={false}>
                                    2. {t('ticket_seats')}
                                </Heading>

                                {!this.urlRequestedCategoryIsAvailable() &&
                                !userChangedSeatSelection ? (
                                    <div className={$.categoryNotAvailable}>
                                        <InfoMessage
                                            text={t('tickets_category_not_available', {
                                                category: this.findActiveCategoryById()?.name,
                                            })}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {isMobile() ? (
                                    <SeatSwiperPicker
                                        venue={eventDetail.venue}
                                        tickets={sortByPrice(this.getTickets())}
                                        selectedTicketCategoryId={this.getActiveTicketCategoryId()}
                                        onSelect={this.handleTickets}
                                    />
                                ) : (
                                    <SeatPicker
                                        eventDetail={eventDetail}
                                        venue={eventDetail.venue}
                                        tickets={sortByPrice(this.getTickets())}
                                        occupancy={Cache.getOccupancy()}
                                        onSelection={this.handleTickets}
                                        selectedTicketCategoryId={this.getActiveTicketCategoryId()}
                                        event={eventDetail}
                                        packageType={packageType}
                                        onConfirmSeats={this.handleNextStepClicked}
                                        disabled={!readyForNextStep}
                                        isLoading={loadingNextStep}
                                    />
                                )}

                                <VSpacer />

                                {!hasPickupAtVenue(
                                    eventDetail.tickets,
                                    this.getActiveTicketCategoryId()
                                ) ? (
                                    <div className={$.ticketDeliveryText}>
                                        <span>
                                            {t('ticket_ticket_delivery_part_1', {
                                                ticketType: getTicketTypesTranslation(
                                                    eventDetail.tickets,
                                                    this.getActiveTicketCategoryId(),
                                                    t
                                                ),
                                            })}{' '}
                                            <strong className={$.ticketDeliveryTextDays}>
                                                {formatTicketDeliveryString(
                                                    eventDetail.ticketSentEarliestDays || 0,
                                                    eventDetail.ticketSentLatestDays || 0,
                                                    t
                                                )}
                                            </strong>{' '}
                                            {t('ticket_ticket_delivery_part_3')}
                                        </span>
                                    </div>
                                ) : (
                                    <div className={$.ticketDeliveryText}>
                                        <span>
                                            {t('ticket_ticket_delivery_part_1', {
                                                ticketType: getTicketTypesTranslation(
                                                    eventDetail.tickets,
                                                    this.getActiveTicketCategoryId(),
                                                    t
                                                ),
                                            })}
                                            <strong className={$.ticketDeliveryTextDays}>
                                                {t('ticket_ticket_delivery_part_1_1')}
                                            </strong>
                                            {t('ticket_ticket_delivery_part_3')}
                                        </span>
                                    </div>
                                )}

                                <ConfirmButton
                                    buttonProps={{
                                        text: confirmButtonText,
                                        icon: '/images/icon-chevron-right.svg',
                                        onClick: this.handleNextStepClicked,
                                        disabled: !readyForNextStep,
                                        isLoading: loadingNextStep,
                                        alignIcon: 'right',
                                        iconClassName: $.chooseHotelButtonIcon,
                                        dataCy: 'confirm-seating-plan-selection',
                                    }}
                                    microCopyText={[t('select_category_micro_copy')]}
                                    onClickPrevious={() => {
                                        this.scrollToPositionFromTop(0);
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </div>
                )}

                <VSpacer />

                {OrderTypeUtil.hasHotel(packageType) && (
                    <div className={$.banner}>
                        <NextStepBanner text={`3. ${t('ticket_pickhotel')}`} />
                    </div>
                )}

                {OrderTypeUtil.onlyHasTicket(packageType) && (
                    <div className={$.banner}>
                        <NextStepBanner text={`3. ${t('ticket_personal_details')}`} />
                    </div>
                )}

                <VSpacer />

                {showChangedPackageTypeMessage && (
                    <Notification
                        notification={
                            changedPackageTypeBecauseNotAvailable
                                ? t('ticket_reset_packagetype_because_not_available', {
                                      packageType: this.getPackageTypeString(packageType),
                                      oldPackageType: this.getPackageTypeString(changedPackageType),
                                  })
                                : t('ticket_reset_packagetype', {
                                      packageType: this.getPackageTypeString(packageType),
                                      oldPackageType: this.getPackageTypeString(changedPackageType),
                                  })
                        }
                        title={t('ticketPackageType_notification_header') as string}
                        onConfirm={handlePackageTypeConfirm}
                        onClose={handleClose}
                    />
                )}
            </Page>
        );
    }
}

Ticket.contextType = MobileContext;

export const TicketPage = connect((state: RootState) => ({
    config: selectPartnerConfig(state),
    eventStatus: selectEventStatus(state),
    eventDetail: selectEventDetail(state),
    session: selectSession(state),
    initializedSession: selectInitializedSession(state),
    order: selectOrder(state),
    orderIsFetching: selectOrderIsFetching(state),
    availability: selectAvailability(state),
    availabilityError: selectAvailabilityError(state),
    orderError: selectErrorAndStatus(state),
    eventSelectedTicketCategoryId: selectSelectedTicketCategoryId(state),
    orderSelectedTicket: selectSelectedTicket(state),
    microCopyVariant: getMicroCopyVariant(state),
}))(withTranslation()(Ticket));
